import React from "react";
import PropTypes from "prop-types";

const CompanySelectOption = ({ photo, label }) => (
  <>
    {photo && (
      <span className="mr-1">
        <img
          src={photo}
          alt={`imagen de ${label}`}
          style={{ height: "35px" }}
        />
      </span>
    )}
    <span>{label}</span>
  </>
);

CompanySelectOption.propTypes = {
  label: PropTypes.string.isRequired,
  photo: PropTypes.string
};

CompanySelectOption.defaultProps = {
  photo: ""
};

export default CompanySelectOption;
