import React, { useCallback } from "react";
import PropTypes from "prop-types";
import Pagination from "react-bootstrap/Pagination";

const Paginator = ({ pagination, setGetParameter }) => {
  const { page, pages } = pagination;

  const renderPaginationNumber = item => {
    return (
      <Pagination.Item
        key={item}
        active={item === page}
        onClick={() => setGetParameter("page", item)}
      >
        {item}
      </Pagination.Item>
    )
  };

  const setItems = useCallback(() => {
    const pagesToAdd = [];
    if (pages < 10) {
      for (let number = 1; number <= pages; number += 1) {
        pagesToAdd.push(renderPaginationNumber(number));
      }
    } else {
      pagesToAdd.push(renderPaginationNumber(1));
      if (pages > 4) pagesToAdd.push(<Pagination.Ellipsis key="e1" />);
      for (let number = page - 2; number <= page + 2; number += 1) {
        if (number > 1 && number < pages)
          pagesToAdd.push(renderPaginationNumber(number));
      }
      if (page < pages - 3) pagesToAdd.push(<Pagination.Ellipsis key="e2" />);
      pagesToAdd.push(renderPaginationNumber(pages));
    }
    return pagesToAdd;
  }, [pagination]);

  const items = setItems();
  return (
    <Pagination>
      <Pagination.First
        disabled={page === 1}
        onClick={() => setGetParameter("page", 1)}
      />
      <Pagination.Prev
        disabled={page === 1}
        onClick={() => setGetParameter("page", page - 1)}
      />
      {items}
      <Pagination.Next
        disabled={page === pages}
        onClick={() => setGetParameter("page", page + 1)}
      />
      <Pagination.Last
        disabled={page === pages}
        onClick={() => setGetParameter("page", pages)}
      />
    </Pagination>
  );
};

Paginator.propTypes = {
  pagination: PropTypes.object.isRequired,
  setGetParameter: PropTypes.func
};

Paginator.defaultProps = {
  setGetParameter: () => {}
};

export default Paginator;
