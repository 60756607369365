import React from "react";
import PropTypes from "prop-types";

const Toast = ({ message, title }) => (
  <div>
    {title && <span className="text-black">{title}</span>}
    {message}
  </div>
);

Toast.propTypes = {
  message: PropTypes.string.isRequired,
  title: PropTypes.string
};

Toast.defaultProps = {
  title: ""
};

export default Toast;
