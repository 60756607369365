import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import Table from "../../components/Table";
import Form from "./Form";
import MassiveLoad from "./MassiveLoad";
import DataTable from 'react-data-table-component';

const Index = ({
  enterprises,
  entities,
  pagination,
  csrf,
  entityClass,
  entityColumns,
  entityReferences,
  personnelDivisions,
  qualifiedLevels,
  entitySubReferences,
  newEntity,
  entityName,
  params,
}) => {
  const setValue = (column, row) => {
    if (column.attribute_type === "boolean")
      return row[column.attribute_name] ? "Si" : "No";
    return row[column.attribute_name];
  };
  

   //const mainParams = useMemo(params, [params])

  const columns = [
    ...entityColumns.map(ec => ({
      ...ec,
      key: ec.attribute_name,
      value: r => setValue(ec, r)
    })),
    ...entityReferences.map(er => ({
      ...er,
      value: r => r[er.attribute_name]?.name,
      attribute_type: "selector"
    })),
  ];

  return (
    <div className="card">
      <div className="card-header d-flex align-items-center">
        <h3 className="card-title mr-auto">{entityName}</h3>
        <a
          className="btn btn-info mx-3"
          href={`/maintainers.xlsx?entity_class=${entityClass}&download=true`}
        >
          <i className="fe fe-download pr-1" />
          Descargar
        </a>
        <a
          className="btn btn-info mx-3"
          href={`/maintainers.xlsx?entity_class=${entityClass}`}
        >
          <i className="fe fe-file pr-1" />
          Plantilla Carga Masiva
        </a>
        <button
          className="btn btn-info mx-3"
          type="button"
          data-bs-toggle="modal"
          data-bs-target="#massiveLoad"
        >
          <i className="fe fe-upload pr-1" />
          Carga Masiva
        </button>
        <button
          className="btn btn-info mx-3"
          type="button"
          data-bs-toggle="modal"
          data-bs-target="#newEntity"
        >
          <i className="fe fe-plus pr-1" />
          {entityName}
        </button>
      </div>
      <div className="card-body">
        <Table
          rows={entities}
          columns={columns}
          actions={["edit", "delete"]}
          entityClass={entityClass}
          pagination={pagination}
          params={params}
          isEntity
          editModal
        />
      </div>
      <br />
      <Form
        qualifiedLevels={qualifiedLevels}
        personnelDivisions={personnelDivisions}
        enterprises={enterprises}
        entity={newEntity}
        csrf={csrf}
        modalId={`newEntity`}
        key={"newEntity.id"}
        columns={columns}
        entityClass={entityClass}
        entityName={entityName}
      />
      <MassiveLoad
        entity={newEntity}
        csrf={csrf}
        modalId={`massiveLoad`}
        key={`massiveLoad-${newEntity.id}`}
        columns={columns}
        entityClass={entityClass}
        entityName={entityName}
      />
      {entities.map(entity => (
        <Form
          qualifiedLevels={qualifiedLevels}
          enterprises={enterprises}
          entity={entity}
          csrf={csrf}
          modalId={`${entityClass}-${entity.id}`}
          key={entity.id}
          columns={columns}
          entityClass={entityClass}
          entityName={entityName}
          personnelDivisions={personnelDivisions}
        />
      ))}
    </div>
  );
};

Index.propTypes = {
  personnelDivisions: PropTypes.array,
  entities: PropTypes.array.isRequired,
  pagination: PropTypes.object.isRequired,
  csrf: PropTypes.string.isRequired,
  newEntity: PropTypes.object.isRequired,
  entityClass: PropTypes.string,
  entityColumns: PropTypes.array,
  entityReferences: PropTypes.array,
  entityName: PropTypes.string
};

Index.defaultProps = {
  entityClass: "Commune",
  entityColumns: [],
  entityReferences: [],
  entitiyName: "Entidad"
}

export default Index;
