import axios from "axios";

export const createEntity = (params, csrf, entityClass) => {
  axios.defaults.headers.common["X-CSRF-Token"] = csrf;

  return axios.post(`/maintainers.json?entity_class=${entityClass}`, {
    entity: params
  });
};

export const createEntities = (params, csrf, entityClass) => {
  axios.defaults.headers.common["X-CSRF-Token"] = csrf;

  return axios.post(`/maintainers.json?massive=true&entity_class=${entityClass}`, {
    entities: params
  });
};

export const updateEntity = (id, params, csrf, entityClass) => {
  axios.defaults.headers.common["X-CSRF-Token"] = csrf;

  return axios.put(`/maintainers/${id}.json?entity_class=${entityClass}`, {
    entity: params
  });
};
