import React from "react";
import PropTypes from "prop-types";
import Table from "../../components/Table";
import Form from "./Form";

const Index = ({
  branch_offices,
  pagination,
  csrf,
  new_branch_office,
  companies
}) => {
  const columns = [
    {
      label: "Nombre",
      key: "name",
      value: r => r.name
    },
    {
      label: "Código",
      key: "code",
      value: r => r.code
    },
    {
      label: "Empresa",
      key: "companies.name",
      value: r => r.company?.name
    }
  ];

  return (
    <div className="card">
      <div className="card-header d-flex justify-content-between align-items-center">
        <h3 className="card-title">Sucursales</h3>
        <button
          className="btn btn-info"
          type="button"
          data-bs-toggle="modal"
          data-bs-target="#newBranchOffice"
        >
          Nueva Sucursal
        </button>
      </div>
      <div className="card-body">
        <Table
          rows={branch_offices}
          columns={columns}
          actions={["edit", "delete"]}
          entityClass="branch_offices"
          pagination={pagination}
          editModal
        />
      </div>
      <Form
        csrf={csrf}
        branchOffice={new_branch_office}
        modalId="newBranchOffice"
        companies={companies}
      />
      {branch_offices.map(branchOffice => (
        <Form
          key={branchOffice.id}
          csrf={csrf}
          branchOffice={branchOffice}
          modalId={`branch_offices-${branchOffice.id}`}
          companies={companies}
        />
      ))}
    </div>
  );
};

Index.propTypes = {
  branch_offices: PropTypes.array.isRequired,
  pagination: PropTypes.object.isRequired,
  csrf: PropTypes.string.isRequired,
  new_branch_office: PropTypes.object.isRequired,
  companies: PropTypes.array
};

Index.defaultProps = {
  companies: []
};

export default Index;
