import React, { useState, useEffect, memo } from "react";
import { PieChart, Tooltip, Pie, ResponsiveContainer, Cell } from "recharts";
import { colours } from "./Utils";

const Graph = ({ data }) => (
  <div
    className="d-flex align-items-center h-100"
    style={{ maxHeight: "400px", minHeight: "200px" }}
  >
    <ResponsiveContainer>
      <PieChart>
        <Pie
          data={data}
          dataKey="value"
          nameKey="name"
          cx="50%"
          cy="50%"
          innerRadius={30}
          outerRadius={90}
        >
          {data.map((entry, index) => (
            <Cell key={entry.name} fill={colours[index]} {...entry} />
          ))}
        </Pie>
        <Tooltip />
      </PieChart>
    </ResponsiveContainer>
  </div>
);

export default memo(Graph);
