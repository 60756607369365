import React, { useState } from "react";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { stateNamesOptions } from "./constants";

const SearchFilter = ({ params, applicants, branchOffices }) => {
  const [filters, setFilters] = useState({
    id: params.id || "",
    application_date: params.application_date || "",
    applicant_id: params.applicant_id || "",
    branch_office_id: params.branch_office_id || "",
    candidate: params.candidate || "",
    status_name: params.status_name || "",
    ocr: params.ocr || ""
  });

  const handleInputChange = event => {
    const {
      target: { name, value }
    } = event;
    setFilters({ ...filters, [name]: value });
  };

  const handleFilter = () => {
    const searcher = new URLSearchParams(window.location.search.substr(1));
    Object.keys(filters).forEach(key => {
      if (filters[key]) {
        searcher.set(key, filters[key]);
      } else if (params[key]) {
        searcher.delete(key);
      }
    });
    searcher.set("page", 1);
    window.location.search = searcher.toString();
  };

  return (
    <div className="input-group">
      <input
        className="form-control"
        name="ocr"
        value={filters.ocr}
        placeholder="Buscar"
        onChange={handleInputChange}
      />
      <div className="input-group-text">
        <OverlayTrigger
          trigger="click"
          placement="bottom-end"
          overlay={
            <Popover>
              <Popover.Content>
                <div className="row g-2 w-100 p-1">
                  <div>
                    <label htmlFor="search-id" className="form-label">
                      ID solicitud
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="search-id"
                      placeholder=""
                      onChange={handleInputChange}
                      name="id"
                      value={filters.id}
                    />
                  </div>
                  <div>
                    <label htmlFor="search-id" className="form-label">
                      Fecha solicitud
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      id="search-application_date"
                      placeholder=""
                      onChange={handleInputChange}
                      name="application_date"
                      value={filters.application_date}
                    />
                  </div>
                  <div>
                    <label htmlFor="search-id" className="form-label">
                      Candidato
                    </label>
                    <input
                      className="form-control"
                      id="search-candidate"
                      placeholder=""
                      onChange={handleInputChange}
                      name="candidate"
                      value={filters.candidate}
                    />
                  </div>
                  <div>
                    <label htmlFor="search-id" className="form-label">
                      Solicitante
                    </label>
                    <select
                      type="date"
                      className="form-control"
                      id="search-applicant_id"
                      placeholder=""
                      onChange={handleInputChange}
                      name="applicant_id"
                      value={filters.applicant_id}
                    >
                      <option value="">Seleccionar</option>
                      {applicants.map(applicant => (
                        <option value={applicant.value} key={applicant.value}>
                          {applicant.label}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label htmlFor="search-id" className="form-label">
                      Sucursal
                    </label>
                    <select
                      type="date"
                      className="form-control"
                      id="search-branch_office_id"
                      placeholder=""
                      onChange={handleInputChange}
                      name="branch_office_id"
                      value={filters.branch_office_id}
                    >
                      <option value="">Seleccionar</option>
                      {branchOffices.map(branchOffice => (
                        <option
                          value={branchOffice.value}
                          key={branchOffice.value}
                        >
                          {branchOffice.label}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label htmlFor="search-id" className="form-label">
                      Estado solicitud
                    </label>
                    <select
                      type="date"
                      className="form-control"
                      id="search-status_name"
                      placeholder=""
                      onChange={handleInputChange}
                      name="status_name"
                      value={filters.status_name}
                    >
                      <option value="">Seleccionar</option>
                      {stateNamesOptions.map(status => (
                        <option value={status.value} key={status.value}>
                          {status.label}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="d-flex justify-content-center">
                    <button
                      type="button"
                      onClick={handleFilter}
                      className="btn btn-info"
                    >
                      Buscar
                    </button>
                  </div>
                </div>
              </Popover.Content>
            </Popover>
          }
        >
          <i className="fe fe-chevron-down text-primary" />
        </OverlayTrigger>
      </div>
    </div>
  );
};

export default SearchFilter;
