import React, { useState } from "react";
import PropTypes from "prop-types";
import Input from "../../components/Forms/Input";
import Errors from "../../components/Layouts/Errors";
import { updateCompany, createCompany } from "../../services/companies";
import CheckboxInput from "../../components/Forms/CheckboxInput";

const Form = ({ company, modalId, csrf }) => {
  const [form, setForm] = useState(company);
  const [errors, setErrors] = useState([]);

  const handleSubmitSuccess = () => {
    const { id } = form;
    alert(`Empresa ${id ? "actualizada" : "creada"} con éxito!`);
    window.location.reload();
  };

  const createOrUpdateCompany = formData => {
    const { id } = form;
    return id
      ? updateCompany(id, formData, csrf)
      : createCompany(formData, csrf);
  };

  const createFormData = () => {
    const formData = new FormData();
    const keys = ["name", "code", "with_contract", "contact_email"];
    keys.forEach(key => {
      formData.append(`company[${key}]`, form[key]);
    });
    if (form.photo) formData.append("company[photo]", form.photo);
    return formData;
  };

  const handleSubmit = e => {
    e.preventDefault();
    createOrUpdateCompany(createFormData()).then(
      () => handleSubmitSuccess(),
      err => setErrors(err.response.data)
    );
  };

  const handleInputChange = event => {
    const {
      target: { name, value }
    } = event;
    setForm({ ...form, [name]: value });
  };

  const handleCheckboxChange = event => {
    const {
      target: { checked, name }
    } = event;
    setForm({ ...form, [name]: checked });
  };

  const handleFileChange = event =>{
    const {
      target: {
        files: [photo]
      }
    } = event;
    setForm({ ...form, photo });
  };

  return (
    <div
      className="modal fade"
      id={modalId}
      tabIndex="-1"
      aria-labelledby={`${modalId}Label`}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id={`${modalId}Label`}>
              Nueva Empresa
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body">
            <form className="row" onSubmit={handleSubmit}>
              <div className="col-12">
                <Errors errors={errors} />
              </div>
              <div className="col-xs-12 col-md-9 col-lg-8">
                <div className="row g-2">
                  <div className="col-xs-12 col-md-6">
                    <Input
                      onChange={handleInputChange}
                      value={form.name}
                      label="Nombre"
                      name="name"
                      id={`${modalId}-companyName`}
                      required
                    />
                  </div>
                  <div className="col-xs-12 col-md-6">
                    <Input
                      onChange={handleInputChange}
                      value={form.code}
                      label="Código"
                      name="code"
                      id={`${modalId}-companyCode`}
                      required
                    />
                  </div>
                  <div className="col-xs-12 col-md-6">
                    <Input
                      onChange={handleInputChange}
                      value={form.contact_email}
                      label="Email de Contacto"
                      name="contact_email"
                      type={"email"}
                      id={`${modalId}-companyCode`}
                      required
                    />
                  </div>
                  <div className="col-12">
                    <CheckboxInput
                      onChange={handleCheckboxChange}
                      checked={form.with_contract}
                      label="Adjuntar contrato laboral"
                      name="with_contract"
                      id={`${modalId}-CompanyWithContract`}
                    />
                  </div>
                  <div className="col-xs-12 col-md-12">
                    <div className="input-group mb-3">
                      <label
                        className="input-group-text"
                        htmlFor={`${modalId}-companyPhoto`}
                      >
                        Imagen
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        id={`${modalId}-companyPhoto`}
                        accept=".png, .jpg"
                        onChange={handleFileChange}
                        name="photo"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-md-3 col-lg-4  d-flex align-items-center">
                <div className="btn-group-vertical w-100">
                  <button type="submit" className="btn btn-outline-success">
                    Guardar
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-warning mt-2"
                    data-bs-dismiss="modal"
                  >
                    Cancelar
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

Form.propTypes = {
  company: PropTypes.object.isRequired,
  modalId: PropTypes.string.isRequired,
  csrf: PropTypes.string.isRequired
};

export default Form;
