import React, { useRef } from "react";
import PropTypes from "prop-types";

const TextArea = ({
  label,
  type,
  id,
  className,
  value,
  required,
  placeholder,
  rows,
  cols,
  ...rest
}) => {
  return (
    <>
      {label && (
        <label htmlFor={id}>
          {required ? "(*)" : ""} {label}
        </label>
      )}

      <textarea
        rows={rows}
        cols={cols}
        id={id}
        className={className}
        type={type}
        placeholder={placeholder || label}
        value={value || ""}
        required={required}
        {...rest}
      />
    </>
  );
};

TextArea.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.oneOf([null]),
  ]),
  required: PropTypes.bool,
  //labeled: PropTypes.bool,
  mask: PropTypes.string,
  rows: PropTypes.number,
  cols: PropTypes.number,
};

TextArea.defaultProps = {
  //labeled: true,
  id: "input",
  rows: 2,
  cols: 20,
  type: "text",
  className: "form-control",
  value: "",
  required: false,
  mask: "",
};

export default TextArea;
