import React, { useMemo } from "react";
import PropTypes from "prop-types";
import Input from "../../../../components/Forms/Input";
import Select from "../../../../components/Forms/Select";
import CheckboxGroup from "../../../../components/Forms/CheckboxGroup";
import MoneyInput from "../../../../components/Forms/MoneyInput";
import Bonus from "../Bonus/Index";
import {
  BIANNUAL_GRATIFICATION_OPTION,
  LEGAL_GRATIFICATION_OPTIONS,
  MONTHLY_GRATIFICATION_OPTION
} from "../constants";
import InputInfo from "../../../../components/Layouts/InputInfo";
import { numberFormat } from "../../../../components/Utils";
import Assignments from "../../../../components/Forms/Assignments";

const RemunerationsForm = ({
  canEdit,
  bonuses,
  form,
  requestBonuses,
  assignments,
  requestAssignments,
  onAddBonus,
  onDeleteBonus,
  onInputChange,
  onSelectChange,
  personalSubGroups,
  onInputBonusChange,
  onBonusSelectChange,
  onAddAssignment,
  onDeleteAssignment,
  onInputAssignmentChange,
  onAssignmentSelectChange,
}) => {
  const gratuityOptions = [
    BIANNUAL_GRATIFICATION_OPTION,
    MONTHLY_GRATIFICATION_OPTION
  ];
  const columns = useMemo(
    () => [
      {
        inputType: "money",
        type: "text",
        label: "Salario Base",
        attribute_name: "base_salary",
        required: true,
        value: f => `$ ${numberFormat(f.base_salary)}`
      },
      {
        inputType: "checkbox",
        label: "Gratificación legal",
        attribute_name: "legal_gratuity",
        required: true,
        options: LEGAL_GRATIFICATION_OPTIONS,
        value: f =>
          LEGAL_GRATIFICATION_OPTIONS.find(l => l.value == f.legal_gratuity)
            ?.label
      },
      {
        inputType: "assignments",
        type: "text",
        label: "Asignación Colación",
        attribute_name: "collation_assignment",
        required: false,

        value: f => `$ ${numberFormat(f.collation_assignment)}`,
      },
      {
        attribute_name: "bonuses",
        value: f => f.time_plan
      },
      {
        inputType: "selector",
        label: "Sub grupo",
        attribute_name: "personal_sub_group_id",
        options: personalSubGroups,
        required: true,
        value: f =>
          personalSubGroups.find(ps => ps.value == f.personal_sub_group_id)
            ?.label
      },
      {
        inputType: "input",
        type: "text",
        label: "Cantidad horas periodo",
        attribute_name: "hours_quantity",
        required: true,
        value: f => f.hours_quantity
      }
    ],
    [
      form.hours_quantity,
      form.personal_sub_group_id,
      form.other_bonus,
      form.liability_bonus,
      form.extreme_condition_bonus,
      form.principal_bonus,
      form.attendance_bonus,
      form.other_assignment,
      form.extra_collation_assignment,
      form.mobilization_assignment,
      form.extra_collation_assignment,
      form.other_assignment_type,
      form.extra_collation_assignment_type,
      form.mobilization_assignment_type,
      form.extra_collation_assignment_type,
      form.legal_gratuity,
      form.base_salary
    ]
  );

  return (
    <div className="card">
      <div className="p-4 border-bottom">
        <h4 className="card-title">Datos Remuneraciones</h4>
      </div>
      <div className="card-body row g-3">
        {columns.map(column => {
          const { inputType, label, attribute_name, value, ...rest } = column;
          if (column.shouldRender === false) return null;
          if (attribute_name.includes("bonuses")) {
            return (
              <Bonus
                key={attribute_name}
                bonuses={bonuses}
                requestBonuses={requestBonuses}
                canEdit={canEdit}
                onAddBonus={onAddBonus}
                onDeleteBonus={onDeleteBonus}
                onInputBonusChange={onInputBonusChange}
                onBonusSelectChange={onBonusSelectChange}
              />
            )
          }
          if (inputType === "assignments") {
            return (
              <Assignments
                key={label}
                canEdit={canEdit}
                assignments={assignments}
                requestAssignments={requestAssignments}
                onAddAssignment={onAddAssignment}
                onDeleteAssignment={onDeleteAssignment}
                onInputAssignmentChange={onInputAssignmentChange}
                onAssignmentSelectChange={onAssignmentSelectChange}
              />
            )
          }
          if (!canEdit) return <InputInfo key={label} title={label} value={value(form) || form[attribute_name]} />;
          if (inputType === "money")
            return (
              <MoneyInput
                key={label}
                value={form[attribute_name]}
                assignmentType={form[`${attribute_name}_type`]}
                name={attribute_name}
                id={attribute_name}
                onChange={onSelectChange}
                label={label}
                {...rest}
              />
            );
          if (inputType === "selector")
            return (
              <Select
                key={attribute_name}
                label={column.label}
                options={column.options}
                value={form[attribute_name]}
                name={attribute_name}
                id={attribute_name}
                onChange={onSelectChange}
                required={column.required}
              />
            );
          if (column.inputType === "checkbox")
            return (
              <CheckboxGroup
                key={label}
                options={column.options}
                value={form[attribute_name]}
                name={attribute_name}
                id={attribute_name}
                title={column.label}
                required={column.required}
                onChange={onInputChange}
              />
            );
          return (
            <Input
              key={attribute_name}
              value={form[attribute_name]}
              name={attribute_name}
              id={attribute_name}
              onChange={onInputChange}
              label={column.label}
              required={column.required}
              type={column.type}
            />
          )
        })}
      </div>
    </div>
  );
};

RemunerationsForm.defaultProps = {
  personalSubGroups: [],
  bonuses: [],
};

RemunerationsForm.propTypes = {
  personalSubGroups: PropTypes.array,
  bonuses: PropTypes.array,
  form: PropTypes.object.isRequired,
  onSelectChange: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired
};

export default RemunerationsForm;
