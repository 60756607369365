import React, { useMemo } from "react";
import PropTypes from "prop-types";
import Input from "../../../../components/Forms/Input";
import Select from "../../../../components/Forms/Select";
import InputInfo from "../../../../components/Layouts/InputInfo";

const ForecastDataForm = ({
  form,
  onSelectChange,
  onInputChange,
  afps,
  healthInstitutions,
  apvs,
  afpConcepts,
  apvConcepts,
  healthConcepts,
  canEdit
}) => {
  const columns = useMemo(
    () => [
      {
        inputType: "selector",
        label: "AFP",
        attribute_name: "afp_id",
        options: afps,
        required: true,
        value: f => afps.find(a => a.value == f.afp_id)?.label
      },
      {
        inputType: "selector",
        label: "Institución de SALUD",
        attribute_name: "health_institution_id",
        options: healthInstitutions,
        required: true,
        value: f =>
          healthInstitutions.find(a => a.value == f.health_institution_id)
            ?.label
      },
      {
        inputType: "input",
        type: "number",
        min: 0,
        step: 0.001,
        label: "Importe Salud",
        attribute_name: "health_amount",
        required: false,
        value: f => f.health_amount
      },
      {
        inputType: "selector",
        label: "APV",
        attribute_name: "apv_id",
        options: apvs,
        required: false,
        value: f => apvs.find(a => a.value == f.apv_id)?.label
      },
      {
        inputType: "input",
        type: "number",
        min: 0,
        step: 0.001,
        label: "Importe APV",
        attribute_name: "apv_amount",
        required: false,
        value: f => f.apv_amount
      }
    ],
    [
      form.afp_id,
      form.afp_concept_id,
      form.health_institution_id,
      form.health_concept_id,
      form.apv_id,
      form.apv_concept_id
    ]
  );

  return (
    <div className="card">
      <div className="p-4 border-bottom">
        <h4 className="card-title">Datos previsionales</h4>
      </div>
      <div className="card-body row g-3">
        {columns.map(column => {
          const { label, inputType, attribute_name, value, ...rest } = column;
          if (!canEdit)
            return (
              <InputInfo
                key={attribute_name}
                title={label}
                value={value(form) || form[attribute_name]}
              />
            );
          if (inputType === "selector")
            return (
              <Select
                key={attribute_name}
                value={form[attribute_name]}
                name={attribute_name}
                id={attribute_name}
                onChange={onSelectChange}
                label={label}
                {...rest}
              />
            );
          return (
            <Input
              key={attribute_name}
              value={form[attribute_name]}
              name={attribute_name}
              id={attribute_name}
              onChange={onInputChange}
              label={label}
              type={column.type}
              {...rest}
            />
          )
        })}
      </div>
    </div>
  );
};

ForecastDataForm.defaultProps = {
  afps: [],
  healthInstitutions: [],
  apvs: [],
  afpConcepts: [],
  apvConcepts: [],
  healthConcepts: []
};

ForecastDataForm.propTypes = {
  afps: PropTypes.array,
  healthInstitutions: PropTypes.array,
  apvs: PropTypes.array,
  afpConcepts: PropTypes.array,
  apvConcepts: PropTypes.array,
  healthConcepts: PropTypes.array,
  form: PropTypes.object.isRequired,
  onSelectChange: PropTypes.func.isRequired
};

export default ForecastDataForm;
