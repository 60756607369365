import axios from "axios";

export const createUser = (params, csrf) => {
  axios.defaults.headers.common["X-CSRF-Token"] = csrf;

  return axios.post("/users.json", { ...params });
};

export const updateUser = (userId, params, csrf) => {
  axios.defaults.headers.common["X-CSRF-Token"] = csrf;

  return axios.put(`/users/${userId}.json`, { ...params });
};
