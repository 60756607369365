export const AddFilesToFormData = (formData, files) => {
  files.forEach(file => {
    if (file.id)
      formData.append(`request[request_files_attributes][][id]`, file.id);
    formData.append(
      `request[request_files_attributes][][file_maintainer_id]`,
      file.file_maintainer_id
    );
    if ((file.signed_id && file.document) || file.document)
      formData.append(
        `request[request_files_attributes][][document]`,
        file.document
      );
  });
};

export const AddCostsToFormData = (formData, costs) => {
  costs.forEach(cost => {
    Object.keys(cost).forEach(key => {
      formData.append(
        `request[cost_distributions_attributes][][${key}]`,
        cost[key]
      );
    });
  });
};
export const AddReferencesToFormData = (formData, references) => {
  references.forEach(reference => {
    Object.keys(reference).forEach(key => {
      if (reference[key] || reference[key] === "")
        formData.append(
          `request[maintainers_attributes][][${key}]`,
          reference[key]
        );
    });
  });
};

export const AddSchedulePlansToFormData = (formData, schedulePlans) => {
  schedulePlans.forEach(schedulePlan => {
    Object.keys(schedulePlan).forEach(key => {
      formData.append(
        `request[schedule_plans_attributes][][${key}]`,
        schedulePlan[key]
      );
    });
  });
};

export const AddBonusesToFormData = (formData, bonuses) => {
  bonuses.forEach(bonus => {
    Object.keys(bonus).forEach(key => {
      if (bonus[key]) {
        formData.append(
          `request[request_bonuses_attributes][][${key}]`,
          bonus[key]
        );
      }
    });
  });
};

export const AddAssignmentsToFormData = (formData, assignments) => {
  assignments.forEach(assignment => {
    Object.keys(assignment).forEach(key => {
      if (assignment[key]) {
        formData.append(
          `request[request_assignments_attributes][][${key}]`,
          assignment[key]
        );
      }
    });
  });
};
