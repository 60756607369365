import React, { useState } from "react";
import { toast } from "react-toastify";
import { useReactToPrint } from "react-to-print";
import { updateStatus } from "../../../services/requests";
import {
  STATUS_ACCEPTED_BY_APPLICANT,
  STATUS_REJECTED_BY_APPLICANT
} from "./constants";

const ApplicantForm = ({ form, csrf, formRef }) => {
  const { id } = form;
  const [loading, setLoading] = useState({
    accept: false,
    reject: false
  });
  const handleOnPrint = useReactToPrint({
    content: () => formRef.current,
    copyStyles: true,
    documentTitle: "Solicitud de inclusión"
  });

  const handleSubmit = (params, name) => {
    setLoading({ ...loading, [name]: true });
    const formData = new FormData();
    Object.keys(params).forEach(key => {
      formData.append(`request[${key}]`, params[key]);
    });
    toast.promise(
      updateStatus(id, formData, csrf),
      {
        pending: "Cargando...",
        success: {
          render: ({ data }) => {
            setTimeout(() => window.location = "/requests", 2000)
            return data.data.status === STATUS_REJECTED_BY_APPLICANT
              ? "El contrato ha sido rechazado"
              : "El contrato ha sido aceptado. La solicitud se da por terminada";
          }
        },
        error: "Ocurrió un error realizando la acción.",
      },
      {
        autoClose: 2000,
        onClose: () => setLoading({ ...loading, [name]: false }),
        pauseOnHover: false
      }
    );
  };

  const handleOnAccept = e => {
    e.preventDefault();
    if (confirm("¿Seguro que quieres confirmar la solicitud?")) {
      handleSubmit({ status: STATUS_ACCEPTED_BY_APPLICANT }, "accept");
    }
  };

  const handleOnReject = e => {
    e.preventDefault();
    if (confirm("¿Seguro que quieres rechazar la solicitud?")) {
      handleSubmit({ status: STATUS_REJECTED_BY_APPLICANT }, "reject");
    }
  };

  return (
    <div className="card">
      <div className="card-body row g-2">
        <div className="col-12">
          <button
            type="button"
            className="btn btn-outline-primary float-right"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-bs-target="print"
            title="Imprimir"
            onClick={handleOnPrint}
          >
            <i className="fe fe-printer" />
          </button>
        </div>

        <div className="col-12 d-flex justify-content-around">
          <span>
            <i className="fe fe-file-text mr-1 text-red" />
            CONTRATO LABORAL
          </span>
          <a
            className="brn btn-outline-secondary"
            href={form.contract_url}
            role="button"
            download
          >
            <i className="fe fe-download" />
          </a>
        </div>

        {form.status !== STATUS_ACCEPTED_BY_APPLICANT && (
          <div className="col-12 mt-5 g-1 p-0 d-flex justify-content-xs-between justify-content-around">
            <button
              type="submit"
              className="btn btn-outline-success"
              onClick={handleOnAccept}
              disabled={loading.accept}
            >
              {loading.accept && (
                <span
                  className="spinner-border spinner-border-sm mr-1"
                  role="status"
                  aria-hidden="true"
                />
              )}
              <i className="fe fe-check" />
              Aceptar
            </button>
            <button
              type="submit"
              className="btn btn-outline-danger"
              onClick={handleOnReject}
              disabled={loading.reject}
            >
              {loading.reject && (
                <span
                  className="spinner-border spinner-border-sm mr-1"
                  role="status"
                  aria-hidden="true"
                />
              )}
              <i className="fe fe-x" />
              Rechazar
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ApplicantForm;
