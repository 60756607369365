export const DateFormat = date => {
  if (!date) return "";
  return new Date(date.split("-")).toLocaleDateString("es-ES", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric"
  });
};

export const rutFormat = string => {
  if (!string) return "-";
  const digit = string?.substring(0, string.length - 1);
  const verificator = string?.substring(string.length - 1);
  return `${digit?.replace(/\B(?=(\d{3})+\b)/g, ".")}-${verificator}`;
};

export const numberFormat = string => {
  if (!string) return "0";
  
  if(string[0] == "0") string = string.replace("0", "");
  const [entire, decimals] = (string || ",").toString()?.split(",");
  const hasDecimals = (string || "").toString().includes(",");
  return `${entire.replace(/\B(?=(\d{3})+\b)/g, ".")}${
    hasDecimals ? `,${decimals}` : ""
  }`;
};

export const nameFormat = string => {
  if (!string) return "";
  return string.replace(/\d/, "");
};
