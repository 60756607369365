import React, { useMemo } from "react";
import PropTypes from "prop-types";
import Input from "../../../../components/Forms/Input";
import Select from "../../../../components/Forms/Select";
import InputInfo from "../../../../components/Layouts/InputInfo";

const BankForm = ({
  form,
  onInputChange,
  onSelectChange,
  banks,
  bankControls,
  paymentMethods,
  canEdit
}) => {
  const bankLabel = useMemo(
    () => banks.find(b => b.value == form.bank_id)?.label,
    [form.bank_id]
  );

  const bankControlLabel = useMemo(
    () => bankControls.find(b => b.value == form.bank_control_id)?.label,
    [form.bank_control_id]
  );

  const paymentMethodLabel = useMemo(
    () => paymentMethods.find(b => b.value == form.payment_method_id)?.label,
    [form.payment_method_id]
  );

  const columns = useMemo(
    () => [
      {
        type: "selector",
        label: "Banco",
        attribute_name: "bank_id",
        options: banks,
        required: true,
        textValue: bankLabel
      },
      {
        type: "input",
        label: "Cuenta",
        attribute_name: "account",
        required: false
      },
      {
        type: "selector",
        label: "Control banco",
        attribute_name: "bank_control_id",
        options: bankControls,
        required: false,
        textValue: bankControlLabel
      },
      {
        type: "selector",
        label: "Vía de pago",
        attribute_name: "payment_method_id",
        options: paymentMethods,
        required: true,
        textValue: paymentMethodLabel
      }
    ],
    [form.bank_id, form.payment_method_id, form.bank_control_id, form.account]
  );

  return (
    <div className="card">
      <div className="p-4 border-bottom">
        <h4 className="card-title">Datos Relación Bancaria</h4>
      </div>
      <div className="card-body row g-3">
        {columns.map(column => {
          const { textValue, attribute_name } = column;
          if (!canEdit) {
            return (
              <InputInfo
                key={column.label}
                title={column.label}
                value={textValue || form[attribute_name]}
              />
            );
          }
          if (column.type === "selector")
            return (
              <Select
                key={attribute_name}
                label={column.label}
                options={column.options}
                value={form[attribute_name]}
                name={attribute_name}
                id={attribute_name}
                onChange={onSelectChange}
                required={column.required}
              />
            );
          return (
            <Input
              key={attribute_name}
              value={form[attribute_name]}
              name={attribute_name}
              id={attribute_name}
              onChange={onInputChange}
              label={column.label}
              required={column.required}
            />
          );
        })}
      </div>
    </div>
  );
};

BankForm.defaultProps = {
  banks: [],
  bankControls: [],
  paymentMethods: []
};

BankForm.propTypes = {
  banks: PropTypes.array,
  bankControls: PropTypes.array,
  paymentMethods: PropTypes.array,
  form: PropTypes.object.isRequired,
  onSelectChange: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired
};

export default BankForm;
