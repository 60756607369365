export const ENDOWMENT_INCREASE_OPTION = "endowment_increase";
export const REPLACEMENT_OPTION = "replacement";
export const BIANNUAL_GRATIFICATION_OPTION = "biannual";
export const MONTHLY_GRATIFICATION_OPTION = "monthly";

export const STATUS_WAITING_FOR_VALIDATION = "waiting_for_validation";
export const STATUS_REJECTED_BY_VALIDATOR = "rejected_by_validator";
export const STATUS_ACCEPTED_BY_VALIDATOR = "accepted_by_validator";
export const STATUS_ACCEPTED_BY_MASTER_DATA = "accepted_by_master_data";
export const STATUS_REJECTED_BY_APPLICANT = "rejected_by_applicant";
export const STATUS_ACCEPTED_BY_APPLICANT = "accepted_by_applicant";

export const defaultCost = {
  cost_center: "",
  internal_order: "",
  percentage: ""
};

export const INCLUSION_REASON_OPTIONS = [
  { label: "Aumento de dotación", value: ENDOWMENT_INCREASE_OPTION },
  { label: "Reemplazo", value: REPLACEMENT_OPTION }
];

export const REPLACEMENT_OPTIONS = [
  { label: "Finiquito", value: "settlement" },
  { label: "Licencia médica", value: "medical_license" },
  { label: "Renuncia", value: "renounce" },
  { label: "Traspaso", value: "transfer" },
  { label: "Vacaciones", value: "vacations" }
];

export const GENDER_OPTIONS = [
  { label: "Femenino", value: "feminine" },
  { label: "Masculino", value: "male" }
];

export const LEGAL_GRATIFICATION_OPTIONS = [
  { label: "Semestral", value: "biannual" },
  { label: "Mensual 25% Tope", value: "monthly" },
  { label: "Mensual 1 Doceavo", value: "twelfth" }
];
