import React from "react";
import PropTypes from "prop-types";

const CheckboxInput = ({ id, label, className, ...rest }) => {
  return (
    <div className="form-check">
      <input type="checkbox" id={id} {...rest} className={className} />
      <label className="form-check-label" htmlFor={id}>
        {label}
      </label>
    </div>
  );
};

CheckboxInput.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string.isRequired
};

CheckboxInput.defaultProps = {
  label: "Nombre",
  className: "form-check-input"
};

export default CheckboxInput;
