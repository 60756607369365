import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";

const Errors = ({ errors }) => {
  const wrapperRef = useRef();

  useEffect(() => {
    if (errors.length) {
      window.scrollTo({
        top: wrapperRef?.current?.offsetTop,
        behavior: "smooth"
      });
    }
  }, [errors]);

  if (!Array.isArray(errors)) {
    return (
      <div className="alert alert-danger" role="alert" ref={wrapperRef}>
        <h5>Ha ocurrido un error guardando los cambios</h5>
      </div>
    );
  }
  if (errors.length === 0) return "";
  return (
    <div className="alert alert-danger" role="alert" ref={wrapperRef}>
      <h4>Los siguientes errores impidieron realizar los cambios:</h4>
      {errors.map(message => (
        <li key={message}>{message}</li>
      ))}
    </div>
  );
};

Errors.defaultProps = {
  errors: []
};

Errors.propTypes = {
  errors: PropTypes.array
};

export default Errors;
