import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import Input from "../../components/Forms/Input";
import CheckboxInput from "../../components/Forms/CheckboxInput";
import Select from "../../components/Forms/Select";
import Errors from "../../components/Layouts/Errors";
import { createEntity, updateEntity } from "../../services/entities";
import TextArea from "../../components/Forms/TextArea";

const Form = ({
  enterprises,
  qualifiedLevels,
  entity,
  modalId,
  csrf,
  columns,
  entityName,
  personnelDivisions,
}) => {
  const [form, setForm] = useState(entity);
  const enterpriseSelectedOption =
    enterprises?.find(
      (e) =>
        e.branch_offices?.find((b) => b.value === form[`branch_office_id`]) !=
        undefined
    )?.value || "";
  const selectedQualifiedOption = qualifiedLevels?.find( q => q.value == form[`qualified_level`])
  const [errors, setErrors] = useState([]);
  const [branchOfficesOptions, setBranchOfficesOptions] = useState(() => {
    if (enterpriseSelectedOption)
      return enterprises.find((e) => e.value == enterpriseSelectedOption)
        .branch_offices;
    return [...enterprises.map((e) => e.branch_offices)];
  });
  const [enterprise, setEnterprise] = useState(enterpriseSelectedOption);
  const [qualifiedLevel, setQualifiedLevel] = useState(selectedQualifiedOption);
  const [divisions, setDivisions] = useState(() => {
    if (form.company_id)
      return personnelDivisions.filter(
        (item) => item.company_id === form.company_id
      );
    return personnelDivisions;
  });

  const qualifiedLevelsOptions = qualifiedLevels.map( q => ({label: q.label, value: q.label}))

  const handleSubmitSuccess = () => {
    const { id } = form;
    alert(`Mantenedor ${id ? "actualizado" : "creado"} con éxito!`);
    window.location.reload();
  };

  const createOrUpdateEntity = () => {
    const { id } = form;
    const entityClass = new URLSearchParams(window.location.search).get(
      "entity_class"
    );
    return id
      ? updateEntity(id, form, csrf, entityClass)
      : createEntity(form, csrf, entityClass);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    createOrUpdateEntity(form, csrf).then(
      () => handleSubmitSuccess(),
      (err) => setErrors(err.response.data)
    );
  };

  const handleInputChange = (event) => {
    const {
      target: { name, value },
    } = event;
    setForm({ ...form, [name]: value });
  };

  const handleDivisionChange = (event) => {
    //setDivisionsSelected(event);
    const newForm = form;
    newForm.personnel_division_id = event.value;
    setForm(newForm);
  };

  const handleCheckboxChange = (event) => {
    const {
      target: { name, checked },
    } = event;
    setForm({ ...form, [name]: checked });
  };

  const handleSelectChange = ({ value }, { name }) => {
    setForm({ ...form, [name]: value });
  };

  const handleEnterpriseChange = ({ value }) => {
    setBranchOfficesOptions(
      enterprises.find((e) => e.value === value).branch_offices
    );
    setEnterprise(value);
    const divisionFiltered = personnelDivisions.filter(
      (item) => item.company_id == value
    );
    setDivisions(divisionFiltered);
  };

  const handleQualifiedLevelChange = ({ value }) => {
    setQualifiedLevel(value);
  };

  return (
    <div
      className="modal fade"
      id={modalId}
      tabIndex="-1"
      aria-labelledby={`${modalId}Label`}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id={`${modalId}Label`}>
              {entityName}
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body">
            <form className="row" onSubmit={handleSubmit}>
              <div className="col-12">
                <Errors errors={errors} />
              </div>
              <div className="col-xs-12 col-md-9 col-lg-8">
                <div className="row g-2">
                  {columns.map((column) => {
                     if (column.attribute_name === "qualified_level") {
                      return (
                        <>
                          <div
                            className="col-xs-12 col-md-6"
                            key={"qualified_level_id"}
                          >
                            <Select
                              key={"qualified_level_selector"}
                              value={form[`${column.attribute_name}`]}
                              options={qualifiedLevelsOptions}
                              name={`qualified_level`}
                              label=""
                              placeholder={"Nivel"}
                              onChange={handleSelectChange}
                            />
                          </div>
                        </>
                      );
                    }
                    if (column.attribute_name === "branch_office") {
                      return (
                        <>
                          <div
                            className="col-xs-12 col-md-6"
                            key={"company_id"}
                          >
                            <Select
                              key={"enterprise_selector"}
                              value={enterprise}
                              options={enterprises}
                              name={`company_id`}
                              label=""
                              placeholder={"Empresa"}
                              onChange={handleEnterpriseChange}
                            />
                          </div>
                          <div
                            className="col-xs-12 col-md-6"
                            key={column.attribute_name}
                          >
                            <Select
                              key={"branch_office_selector"}
                              value={form[`${column.attribute_name}_id`]}
                              options={branchOfficesOptions}
                              name={`${column.attribute_name}_id`}
                              label=""
                              placeholder={column.label}
                              onChange={handleSelectChange}
                            />
                          </div>
                        </>
                      );
                    }
                    if (column.attribute_name === "personnel_division") {
                      return (
                        <>
                          <div
                            className="col-xs-12 col-md-6"
                            key={"personnel_division_id"}
                          >
                            <Select
                              key={"division_selector"}
                              value={form.personnel_division_id}
                              options={divisions}
                              name={`personnel_division_id`}
                              label=""
                              placeholder={"División de Personal"}
                              onChange={handleSelectChange}
                            />
                          </div>
                        </>
                      );
                    }
                    if (column.attribute_type === "selector") {
                      return (
                        <div
                          className="col-xs-12 col-md-6"
                          key={column.attribute_name}
                        >
                          <Select
                            value={form[`${column.attribute_name}_id`]}
                            options={column.options}
                            name={`${column.attribute_name}_id`}
                            label=""
                            placeholder={column.label}
                            onChange={handleSelectChange}
                          />
                        </div>
                      );
                    }
                    if (column.attribute_type === "boolean") {
                      return (
                        <div
                          className="col-xs-12 col-md-6"
                          key={column.attribute_name}
                        >
                          <CheckboxInput
                            checked={form[column.attribute_name]}
                            name={column.attribute_name}
                            label={column.label}
                            onChange={handleCheckboxChange}
                            id={`entity-${column.attribute_name}-${modalId}`}
                          />
                        </div>
                      );
                    }
                    if (column.attribute_type === "text") {
                      return (
                        <div
                          className="col-xs-12 col-md-6"
                          key={column.attribute_name}
                        >
                          <TextArea
                            value={form[column.attribute_name] || ""}
                            name={column.attribute_name}
                            label={column.label}
                            placeholder={column.label}
                            onChange={handleInputChange}
                            id={`entity-input-${column.attribute_name}`}
                            required
                          />
                        </div>
                      );
                    }
                    return (
                      <div
                        className="col-xs-12 col-md-6"
                        key={column.attribute_name}
                      >
                        <Input
                          value={form[column.attribute_name] || ""}
                          name={column.attribute_name}
                          label={column.label}
                          placeholder={column.label}
                          onChange={handleInputChange}
                          id={`entity-input-${column.attribute_name}`}
                          required
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="col-xs-12 col-md-3 col-lg-4 d-flex align-items-center">
                <div className="btn-group-vertical w-100">
                  <button type="submit" className="btn btn-outline-success">
                    Guardar
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-warning mt-2"
                    data-bs-dismiss="modal"
                  >
                    Cancelar
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

Form.propTypes = {
  company: PropTypes.object,
  modalId: PropTypes.string.isRequired,
  csrf: PropTypes.string.isRequired,
};

Form.defaultProps = {
  company: {},
};

export default Form;
