import React, { memo, useEffect, useState, useRef, useCallback, useMemo } from "react";
import { toast } from "react-toastify";
import Toast from "../../../../components/Layouts/Toast";
import { createComment, getComments } from "../../../../services/requests";
import Consumer from "../../../../channels/consumer";
import Comment from "./Comment";

const Index = ({ user, requestId, csrf }) => {
  const [form, setForm] = useState({
    content: "",
    user_id: user.id,
    request_id: requestId
  });
  const [comments, setComments] = useState([]);
  Consumer.subscriptions.create(
    {
      channel: "CommentsChannel",
      request_id: requestId
    },
    {
      received: ({ comment, action }) => commentActions[action](comment)
    }
  );

  const addComment = comment => setComments([comment, ...comments]);

  const updateComment = comment => {
    const commentIndex = comments.findIndex(c => c.id == comment.id);
    if (commentIndex < 0) return;
    const newComments = comments.slice();
    newComments[commentIndex] = comment;
    setComments(newComments);
  };

  const deleteComment = comment => {
    const commentIndex = comments.findIndex(c => c.id == comment.id);
    if (commentIndex < 0) return;
    const newComments = comments.slice();
    newComments.splice(commentIndex, 1);
    setComments(newComments);
  };

  const commentActions = useMemo(() => ({
    create: comment => addComment(comment),
    update: comment => updateComment(comment),
    destroy: comment => deleteComment(comment)
  }));

  const handleInputChage = event => {
    const {
      target: { name, value }
    } = event;
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = event => {
    event.preventDefault();
    toast.promise(
      createComment(requestId, form, csrf),
      {
        pending: "Cargando...",
        success: {
          render() {
            setForm({ ...form, content: "" });
            return "Comentario añadido con éxito";
          }
        },
        error: "Ocurrió un error añadiendo el comentario."
      },
      {
        autoClose: 1700
      }
    );
  };

  useEffect(() => {
    const actualConsumer = Consumer.subscriptions.subscriptions.find(
      s => JSON.parse(s.identifier).request_id == requestId
    );
    getComments(requestId).then(
      response => setComments(response.data),
      error => console.log(error.response)
    );
    return () => actualConsumer?.unsubscribe();
  }, []);

  const componentStyle = useMemo(() => ({ maxHeight: "500px" }), []);

  return (
    <div className="card overflow-scroll" style={componentStyle}>
      <div className="card-header">
        <h3 className="card-title">Comentarios</h3>
      </div>
      <div className="card-body row g-2">
        <form onSubmit={handleSubmit}>
          <div className="input-group">
            <textarea
              className="form-control"
              aria-label="Escribe un comentario"
              placeholder="Escribe un comentario"
              onChange={handleInputChage}
              value={form.content}
              name="content"
              required
            />
            <button type="submit" className="input-group-text">
              <i className="fe fe-send text-primary" />
            </button>
          </div>
        </form>
        {comments.map((comment, idx) => (
          <Comment
            comment={comment}
            userId={user.id}
            key={comment.id}
            previousComment={comments[idx - 1]}
          />
        ))}
      </div>
    </div>
  );
};

export default memo(Index);
