/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
import React, { useState, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import Input from "../../components/Forms/Input";
import CheckboxGroup from "../../components/Forms/CheckboxGroup";
import { ROLE_ADMIN, ROLE_APPLICANT, ROLE_GENERALIST, ROLE_MASTER_DATA, ROLE_SUPERADMIN } from "./constants";
import { createUser, updateUser } from "../../services/users";
import Errors from "../../components/Layouts/Errors";

const Form = ({
  roles,
  user,
  current_user,
  companies,
  branch_offices,
  csrf,
  user_companies,
  editing_profile,
  permissions,
  user_permissions
}) => {
  const [form, setForm] = useState(user);
  const [userCompanies, setUserCompanies] = useState(user_companies);
  const [userPermissions, setUserPermissions] = useState(user_permissions);
  const [errors, setErrors] = useState([]);

  const handleInputChange = event => {
    const {
      target: { name, value }
    } = event;
    setForm({ ...form, [name]: value });
  };

  const createOrUpdateUser = params => {
    const { id } = form;
    return id ? updateUser(id, params, csrf) : createUser(params, csrf);
  };

  const handleSubmitSuccess = () => {
    const { id, role } = form;
    alert(`¡Usuario ${id ? "actualizado" : "creado"} con éxito!`);
    const redir_location = [ROLE_ADMIN, ROLE_SUPERADMIN].includes(current_user.role) ? "/users" : "/users/profile"
    window.location = redir_location;
  };

  const handleSubmit = e => {
    e.preventDefault();
    createOrUpdateUser({
      user: {
        ...form,
        user_companies_attributes: userCompanies,
        user_permissions_attributes: userPermissions
    }
    }).then(
      () => handleSubmitSuccess(),
      err => setErrors(err.response.data)
    );
  };

  const handleCheckboxChange = event => {
    const {
      target: { name, value }
    } = event;
    setForm({ ...form, [name]: value });
  };

  const handleCompanyChange = event => {
    const {
      target: { value, checked }
    } = event;
    const newCompanies = userCompanies.slice();
    const companyIndex = newCompanies.findIndex(c => c.company_id == value);
    if (checked) {
      if (companyIndex >= 0) {
        newCompanies[companyIndex]._destroy = false;
      } else {
        newCompanies.push({
          company_id: value,
          user_company_branch_offices_attributes: []
        });
      }
    }
    if (!checked && companyIndex >= 0) {
      newCompanies[companyIndex]._destroy = true;
    }
    setUserCompanies(newCompanies);
  };

  const handlePermissionChange = event => {
    const {
      target: { value, checked }
    } = event;
    const newPermissions = userPermissions.slice();
    const permissionIndex = newPermissions.findIndex(c => c.permission_id == value);
    
    if (checked) {
      if (permissionIndex >= 0) {
        newPermissions[permissionIndex]._destroy = false;
      } else {
        newPermissions.push({
          permission_id: value,
          user_id: user.id,
        });
      }
    }

    if (!checked && permissionIndex >= 0) {
      newPermissions[permissionIndex]._destroy = true;
    }
    setUserPermissions(newPermissions);
  };

  const handleBranchOfficeChange = event => {
    const {
      target: { value, checked }
    } = event;
    const [branchOfficeId, companyId] = value?.split("-");
    const newCompanies = userCompanies.slice();
    let companyIndex = newCompanies.findIndex(c => c.company_id == companyId);
    let branchOfficeIndex = -1;
    if (companyIndex >= 0) {
      branchOfficeIndex = newCompanies[
        companyIndex
      ].user_company_branch_offices_attributes.findIndex(
        bo => bo.branch_office_id == branchOfficeId
      );
    } else {
      newCompanies.push({
        company_id: companyId,
        user_company_branch_offices_attributes: []
      });
      companyIndex = 0;
    }
    if (checked) {
      if (branchOfficeIndex >= 0) {
        newCompanies[companyIndex].user_company_branch_offices_attributes[
          branchOfficeIndex
        ]._destroy = false;
      } else {
        newCompanies[companyIndex].user_company_branch_offices_attributes.push({
          branch_office_id: branchOfficeId
        });
      }
    }
    if (!checked && branchOfficeIndex >= 0) {
      newCompanies[companyIndex].user_company_branch_offices_attributes[
        branchOfficeIndex
      ]._destroy = true;
    }
    setUserCompanies(newCompanies);
  };

  const branchOfficesOptions = useMemo(() => {
    return branch_offices
      .filter(bo =>
        userCompanies
          .filter(c => c._destroy !== true)
          .map(c => c.company_id.toString())
          .includes(bo.company_id?.toString())
      )
      .map(bo => ({
        label: `${bo.code} ${bo.name}`,
        value: `${bo.id}-${bo.company_id}`
      }));
  }, [userCompanies]);

  const userBranchOffices = useMemo(() => {
    let selecteds = [];
    userCompanies
      .filter(c => c._destroy !== true).forEach(uc => {
        selecteds = [
          ...selecteds,
          ...uc.user_company_branch_offices_attributes
            .filter(bo => (bo._destroy ? bo._destroy !== true : true))
            .map(bo => `${bo.branch_office_id}-${uc.company_id}`)
        ];
      });
    return selecteds;
  }, [userCompanies]);

  const companiesSelected = useMemo(() => {
    return userCompanies
      .filter(c => (c._destroy ? c._destroy !== true : true))
      .map(c => c.company_id);
  }, [userCompanies]);

  const permissionsSelected = useMemo(() => {
    return userPermissions
      .filter(p => (p._destroy ? p._destroy !== true : true))
      .map(p => p.permission_id);
  }, [userPermissions]);

  const userRoleIs = useCallback(
    options => {
      return options.includes(form.role);
    },
    [form.role]
  );

  return (
    <form className="needs-validation" onSubmit={handleSubmit}>
      <Errors errors={errors} />
      <div className="row g-3">
        <div className="col-md-6 col-xs-12">
          <Input
            label="Email"
            value={form.email}
            name="email"
            onChange={handleInputChange}
            id="userEmail"
            type="email"
            required
            disabled={editing_profile}
          />
        </div>
        <div className="col-md-6 col-xs-12">
          <Input
            label="Nombre Completo"
            value={form.name}
            name="name"
            onChange={handleInputChange}
            id="userName"
            required
          />
        </div>
        <div className="col-md-6 col-xs-12">
          <Input
            label="Contraseña"
            value={form.password}
            name="password"
            onChange={handleInputChange}
            id="userPassword"
            type="password"
            required={!form.id}
          />
        </div>
        <div className="col-md-6 col-xs-12">
          <Input
            label="Confirmar Contraseña"
            value={form.password_confirmation}
            name="password_confirmation"
            onChange={handleInputChange}
            id="userPasswordConfirmation"
            type="password"
            required={!form.id}
          />
        </div>
      </div>
      {!editing_profile && (
        <div className="row mt-5">
          <div className="col-md-4 col-xs-12">
            <CheckboxGroup
              title="Perfil"
              options={roles}
              onChange={handleCheckboxChange}
              name="role"
              value={form.role}
              required
            />
          </div>
          {userRoleIs([ROLE_APPLICANT, ROLE_GENERALIST]) && (
            <>
              <div className="col-md-4 col-xs-12">
                <CheckboxGroup
                  title="Empresa"
                  options={companies}
                  onChange={handleCompanyChange}
                  name="companies"
                  value={companiesSelected}
                  isMulti
                />
              </div>
              <div className="col-md-4 col-xs-12">
                <CheckboxGroup
                  title="Sucursal"
                  options={branchOfficesOptions}
                  onChange={handleBranchOfficeChange}
                  name="branch_office"
                  value={userBranchOffices}
                  isMulti
                />
              </div>
            </>
          )}
          {userRoleIs([ROLE_GENERALIST, ROLE_MASTER_DATA]) && (
            <>
              <div className="col-md-4 col-xs-12">
                <CheckboxGroup
                  title="Permisos"
                  options={permissions}
                  onChange={handlePermissionChange}
                  name="permissions"
                  value={permissionsSelected}
                  isMulti
                />
              </div>
            </>
          )}
        </div>
      )}
      <div className="row mt-2">
        <div className="col-12">
          <button className="btn btn-danger scp-primary" type="submit">
            Subir
          </button>
        </div>
      </div>
    </form>
  );
};

Form.defaultProps = {
  roles: [],
  companies: [],
  branch_offices: [],
  permissions: [],
  user_permissions: [],
};

Form.propTypes = {
  roles: PropTypes.array,
  user: PropTypes.object.isRequired,
  companies: PropTypes.array,
  branch_offices: PropTypes.array,
  permissions: PropTypes.array,
  user_permissions: PropTypes.array,
};

export default Form;
