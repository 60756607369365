import React, { memo, useCallback, useMemo, useState } from "react";
import {
  deleteComment,
  updateComment,
  updateCommentNotification
} from "../../../../services/requests";

const Comment = ({ comment, userId, previousComment }) => {
  const { request_id, id, user_id } = comment;
  const [form, setForm] = useState(comment);

  const handleDelete = () => {
    deleteComment(id, request_id);
  };

  const handleSubmit = event => {
    event.preventDefault();
    updateComment(id, request_id, form).then(
      () => setForm({ ...form, editing: false }),
      err => console.log(err)
    );
  };

  const handleInputChage = event => {
    const {
      target: { name, value }
    } = event;
    setForm({ ...form, [name]: value });
  };

  const handleMouseOver = notificationIndex => {
    const notification = comment.comment_notifications[notificationIndex];
    if (!notification || notification?.viewed) return;
    updateCommentNotification(request_id, user_id, notification.id).then(() => {
      const newNotifications = comment.comment_notifications.slice();
      newNotifications[notificationIndex].viewed = true;
      setForm({ ...form, comment_notifications: newNotifications });
    });
  };

  const isSameUser = useMemo(() => userId == user_id, [comment]);
  const samePreviousCommentUser = useMemo(
    () => user_id == previousComment?.user_id,
    [previousComment]
  );
  const [notification, viewed] = useMemo(() => {
    const index = form.comment_notifications.findIndex(
      cn => cn.user_id == userId
    );
    if (index < 0) return [index, true];
    return [index, form.comment_notifications[index]?.viewed];
  }, [form.comment_notifications]);

  const dateOptions = useMemo(() => ({
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit"
  }));

  return (
    <div
      className="row mt-3"
      onMouseEnter={() => handleMouseOver(notification)}
    >
      <div className={`col-2 order-${isSameUser ? 2 : 1} mt-2`}>
        {!samePreviousCommentUser && (
          <i className="fe fe-user rounded-circle border border-secondary p-1" />
        )}
      </div>
      <div
        className={`bg-light text-secondary col-10 order-${
          isSameUser ? 1 : 2
        } w-75`}
      >
        <div className="d-flex justify-content-between">
          <strong>{!samePreviousCommentUser && comment.user?.name}</strong>
          {isSameUser && (
            <div className="dropdown">
              <button
                type="button"
                className="btn btn-light"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="fe fe-more-vertical" />
              </button>
              <ul className="dropdown-menu">
                <li>
                  <button
                    className="dropdown-item"
                    type="button"
                    onClick={() => setForm({ ...form, editing: true })}
                  >
                    Editar
                  </button>
                </li>
                <li>
                  <button
                    className="dropdown-item"
                    type="button"
                    onClick={handleDelete}
                  >
                    Eliminar
                  </button>
                </li>
              </ul>
            </div>
          )}
          {!viewed && (
            <span className="position-absolute top-0 start-100 translate-middle p-2 bg-danger border border-light rounded-circle">
              <span className="visually-hidden"></span>
            </span>
          )}
        </div>
        {form.editing ? (
          <form onSubmit={handleSubmit}>
            <div className="input-group">
              <textarea
                className="form-control"
                aria-label="Escribe un comentario"
                onChange={handleInputChage}
                value={form.content}
                name="content"
                required
              />
              <button type="submit" className="input-group-text">
                <i className="fe fe-send text-primary" />
              </button>
              <button
                type="button"
                className="input-group-text"
                onClick={() =>
                  setForm({ ...form, editing: false, content: comment.content })
                }
              >
                <i className="fe fe-x text-danger" />
              </button>
            </div>
          </form>
        ) : (
          <p className="p-2">{comment.content}</p>
        )}
        <div className="row">
          <cite className="text-end">
            {new Date(comment.created_at)?.toLocaleString("es-ES", dateOptions)}
          </cite>
        </div>
      </div>
    </div>
  );
};

export default memo(Comment);
