import React from "react";
import PropTypes from "prop-types";
import Table from "../../components/Table";

const Index = ({ users, pagination, params }) => {
  const columns = [
    {
      label: "Email",
      key: "email",
      value: r => r.email
    },
    {
      label: "Nombre",
      key: "name",
      value: r => r.name
    },
    {
      label: "Perfil",
      key: "role",
      value: r => r.role_name
    },
    {
      label: "Última conexión",
      key: "last_sign_in_at",
      value: r =>
        r.last_sign_in_at
          ? new Date(r.last_sign_in_at).toLocaleString("es-ES", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
              hour: "2-digit",
              minute: "2-digit"
            })
          : ""
    }
  ];

  return (
    <Table
      rows={users}
      columns={columns}
      actions={["edit", "delete"]}
      entityClass="users"
      pagination={pagination}
      params={params}
    />
  );
};

Index.propTypes = {
  users: PropTypes.array.isRequired,
  pagination: PropTypes.object.isRequired
};

export default Index;
