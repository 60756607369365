import axios from "axios";

export const createBranchOffice = (params, csrf) => {
  axios.defaults.headers.common["X-CSRF-Token"] = csrf;

  return axios.post("/branch_offices.json", { ...params });
};

export const updateBranchOffice = (branchOfficeId, params, csrf) => {
  axios.defaults.headers.common["X-CSRF-Token"] = csrf;

  return axios.put(`/branch_offices/${branchOfficeId}.json`, { ...params });
};
