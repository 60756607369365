import React, { useMemo } from "react";
import PropTypes from "prop-types";
import Input from "../../../../components/Forms/Input";
import CheckboxGroup from "../../../../components/Forms/CheckboxGroup";
import Select from "../../../../components/Forms/Select";
import RutInput from "../../../../components/Forms/RutInput";
import InputInfo from "../../../../components/Layouts/InputInfo";
import { rutFormat, DateFormat, nameFormat } from "../../../../components/Utils";
import CompanyOption from "../../../../components/Layouts/CompanySelectOption";
import {
  INCLUSION_REASON_OPTIONS,
  REPLACEMENT_OPTIONS,
  REPLACEMENT_OPTION,
  GENDER_OPTIONS
} from "../constants";
import TextArea from "../../../../components/Forms/TextArea";

const MainForm = ({
  form,
  onInputChange,
  onSelectChange,
  regions,
  communes,
  extremeZones,
  nacionalities,
  companies,
  branchOffices,
  relationships,
  canEdit
}) => {
  const [communeLabel, communesOptions] = useMemo(() => {
    if (!form.region_id) return ["", communes];
    let label = "";
    const options = [];
    communes.forEach(commune => {
      if (commune.region_id == form.region_id) options.push(commune);
      if (commune.value == form.commune_id) ({ label } = commune);
    });
    return [label, options];
  }, [form.region_id]);

  const [branchOfficeLabel, branchOfficesOptions] = useMemo(() => {
    if (!form.company_id) return ["", branchOffices];
    let label = "";
    const options = [];
    branchOffices.forEach(branchOffice => {
      if (branchOffice.company_id == form.company_id) options.push(branchOffice);
      if (branchOffice.value == form.branch_office_id) ({ label } = branchOffice);
    });
    return [label, options];
  }, [form.company_id]);

  const company = useMemo(() => companies.find(c => c.value == form.company_id), [
    form.company_id
  ]);

  const inclusionColumns = useMemo(
    () => [
      {
        inputType: "checkbox",
        label: "Motivo",
        attribute_name: "inclusion_reason",
        required: true,
        options: INCLUSION_REASON_OPTIONS,
        value: f =>
          INCLUSION_REASON_OPTIONS.find(i => i.value == f.inclusion_reason)
            ?.label
      },
      {
        inputType: "selector",
        label: "Motivo Reemplazo",
        attribute_name: "replacement_reason",
        options: REPLACEMENT_OPTIONS,
        required: true,
        shouldRender: form.inclusion_reason === REPLACEMENT_OPTION,
        value: f =>
          REPLACEMENT_OPTIONS.find(i => i.value == f.replacement_reason)?.label
      },
      {
        inputType: "selector",
        label: "Empresa",
        attribute_name: "company_id",
        options: companies,
        required: true,
        value: () => company?.label,
        formatOptionLabel: option => {
          const { label, photo } = option;
          return <CompanyOption photo={photo} label={label || ""} />;
        }
      },
      {
        inputType: "selector",
        label: "Sucursal",
        attribute_name: "branch_office_id",
        options: branchOfficesOptions,
        required: true,
        value: () => branchOfficeLabel
      },
      {
        inputType: "textArea",
        type: "textarea",
        rows: 3,
        label: "Observaciones",
        attribute_name: "observations",
        value: f => f.observations
      },
    ],
    [
      form.company_id,
      form.branch_office_id,
      form.replacement_reason_id,
      form.inclusion_reason,
      form.observations,
    ]
  );

  const candidateColumns = useMemo(
    () => [
      {
        inputType: "input",
        type: "text",
        label: "Nombre",
        attribute_name: "candidate_name",
        required: true,
        value: f => { f.candidate_name = nameFormat(f.candidate_name); return f.candidate_name }
      },
      {
        inputType: "input",
        type: "text",
        label: "Apellido Paterno",
        attribute_name: "candidate_lastname",
        required: true,
        value: f => f.candidate_lastname
      },
      {
        inputType: "input",
        type: "text",
        label: "Apellido Materno",
        attribute_name: "candidate_second_lastname",
        required: true,
        value: f => f.candidate_second_lastname
      },
      {
        inputType: "rut",
        type: "text",
        label: "RUT",
        attribute_name: "identity_card",
        required: true,
        value: f => rutFormat(f.identity_card)
      },
      {
        inputType: "input",
        type: "date",
        label: "Fecha de Nacimiento",
        attribute_name: "birthday",
        required: true,
        value: f => DateFormat(f.birthday)
      },
      {
        inputType: "checkbox",
        label: "Sexo",
        attribute_name: "gender",
        required: true,
        options: GENDER_OPTIONS,
        value: f => GENDER_OPTIONS.find(g => g.value == f.gender)?.label
      },
      {
        inputType: "selector",
        label: "Nacionalidad",
        attribute_name: "nacionality_id",
        options: nacionalities,
        required: true,
        value: f => nacionalities.find(n => n.value == f.nacionality_id)?.label
      },
      {
        inputType: "input",
        type: "text",
        label: "Domicilio Calle",
        attribute_name: "address",
        required: true,
        value: f => f.address
      },
      {
        inputType: "input",
        type: "number",
        label: "Domicilio Número de Calle",
        min: 0,
        attribute_name: "address_number",
        required: true,
        value: f => f.address_number
      },
      {
        inputType: "input",
        type: "text",
        label: "Departamento",
        attribute_name: "department",
        required: false,
        value: f => f.department
      },
      {
        inputType: "input",
        type: "text",
        label: "Población",
        attribute_name: "town",
        required: false,
        value: f => f.town
      },
      {
        inputType: "selector",
        label: "Región",
        attribute_name: "region_id",
        options: regions,
        required: true,
        value: f => regions.find(r => r.value == f.region_id)?.label
      },
      {
        inputType: "selector",
        label: "Comuna",
        attribute_name: "commune_id",
        options: communesOptions,
        required: true,
        value: () => communeLabel
      },
      {
        inputType: "selector",
        label: "Zona Extrema",
        attribute_name: "extreme_zone_id",
        options: extremeZones,
        required: false,
        value: f =>
          extremeZones.find(ez => ez.value == f.extreme_zone_id)?.label
      },
      {
        inputType: "input",
        onInput: (e) => {
          let max = e.target.attributes.maxlenght.value;
          if (e.target.value.length > max) e.target.value = e.target.value.slice(0, max);
        },
        type: "number",
        maxlenght: 9,
        label: "Celular Ej: 912345678",
        placeholder: "Celular - Ej: 912345678",
        attribute_name: "cellphone_number",
        required: false,
        value: f => f.cellphone_number
      },
      {
        inputType: "input",
        type: "email",
        label: "Email",
        attribute_name: "email",
        required: Boolean(form.email),
        value: f => f.email
      },
      {
        inputType: "input",
        type: "email",
        label: "Email coorporativo",
        attribute_name: "candidate_corporate_email",
        // required: Boolean(form.candidate_corporate_email),
        value: f => f.candidate_corporate_email
      },
      {
        inputType: "input",
        type: "text",
        label: "Nombre Contacto de  Emergencia",
        attribute_name: "emergency_name",
        required: true,
        value: f => f.emergency_name
      },
      {
        inputType: "selector",
        label: "Parentesco Contacto de  Emergencia",
        attribute_name: "emergency_relationship",
        options: relationships,
        required: true,
        value: f =>
          relationships.find(rel => rel.value == f.emergency_relationship)?.label
      },
      {
        inputType: "input",
        onInput: (e) => {
          let max = e.target.attributes.maxlenght.value;
          if (e.target.value.length > max) e.target.value = e.target.value.slice(0, max);
        },
        type: "number",
        maxlenght: 9,
        placeholder: "Número Contacto de emergencia - Ej: 912345678",
        label: "Número Contacto de emergencia Ej: 912345678",
        attribute_name: "emergency_number",
        required: true,
        value: f => f.emergency_number
      },
      {
        inputType: "input",
        type: "text",
        label: "Nombre Contacto de Emergencia 2",
        attribute_name: "emergency_contact_name",
        value: f => f.emergency_contact_name
      },
      {
        inputType: "selector",
        label: "Parentesco Contacto de  Emergencia 2",
        attribute_name: "emergency_contact_relationship",
        options: relationships,
        value: f =>
          relationships.find(rel => rel.value == f.emergency_contact_relationship)?.label
      },
      {
        inputType: "input",
        onInput: (e) => {
          let max = e.target.attributes.maxlenght.value;
          if (e.target.value.length > max) e.target.value = e.target.value.slice(0, max);
        },
        type: "number",
        maxlenght: 9,
        placeholder: "Número Contacto de emergencia 2 - Ej: 912345678",
        label: "Número Contacto de emergencia 2 Ej: 912345678",
        attribute_name: "emergency_contact_number",
        value: f => f.emergency_contact_number
      },
    ],
    [
      form.emergency_name,
      form.emergency_relationship,
      form.emergency_number,
      form.emergency_contact_name,
      form.emergency_contact_relationship,
      form.emergency_contact_number,
      form.email,
      form.cellphone,
      form.extreme_zone_id,
      form.commune_id,
      form.region_id,
      form.gender,
      form.nacionality_id,
      form.town,
      form.department,
      form.address,
      form.address_number,
      form.birthday,
      form.identity_card,
      form.candidate_name,
      form.candidate_lastname,
      form.candidate_second_lastname,
      form.candidate_corporate_email
    ]
  );

  const columns = [
    { name: "Datos de inclusión", columns: inclusionColumns },
    { name: "Datos personales", columns: candidateColumns }
  ];

  return (
    <>
      {company && (
        <>
          <div className="d-flex justify-content-center align-items-center mb-2">
            {company.photo && (
              <img
                className="mr-2 p-2 border-0"
                src={company.photo}
                alt={`Imagen de ${company.label}`}
                style={{ maxHeight: "150px", maxWidth: "300px" }}
              />
            )}
            <strong>{company.label}</strong>
          </div>
        </>
      )}
      <div className="d-flex justify-content-center align-items-center mb-2">
        <strong className={"fs-6"}>Ficha: {form.id}</strong>
      </div>
      {columns.map(column => (
        <div className="card" key={column.name}>
          <div className="p-4 border-bottom">
            <h3 className="card-title">{column.name}</h3>
          </div>
          <div className="card-body row g-3">
            {column.columns.map(col => {
              if (col.shouldRender === false) return null;
              const { attribute_name, label, value, inputType, ...rest } = col;
              if (!canEdit)
                return (
                  <InputInfo
                    type={inputType}
                    key={attribute_name}
                    title={label}
                    value={value(form) || form[attribute_name]}
                  />
                );
              if (inputType === "selector")
                return (
                  <Select
                    key={attribute_name}
                    label={col.label}
                    value={form[attribute_name]}
                    name={attribute_name}
                    id={attribute_name}
                    onChange={onSelectChange}
                    {...rest}
                  />
                );
              if (inputType === "checkbox")
                return (
                  <CheckboxGroup
                    key={attribute_name}
                    value={form[attribute_name]}
                    name={attribute_name}
                    id={attribute_name}
                    title={col.label}
                    onChange={onInputChange}
                    {...rest}
                  />
                );
              if (inputType === "rut")
                return (
                  <RutInput
                    key={attribute_name}
                    label={label}
                    value={form[attribute_name]}
                    onChange={onSelectChange}
                    name={attribute_name}
                    id={attribute_name}
                    required={col.required}
                  />
                );
              if (inputType === "textArea")
                return (
                  <TextArea
                    key={attribute_name}
                    value={form[attribute_name]}
                    name={attribute_name}
                    id={attribute_name}
                    onChange={onInputChange}
                    label={col.label}
                    {...rest}
                  />
                );
              return (
                <Input
                  key={attribute_name}
                  value={form[attribute_name]}
                  name={attribute_name}
                  id={attribute_name}
                  onChange={onInputChange}
                  label={col.label}
                  {...rest}
                />
              );
            })}
          </div>
        </div>
      ))}
    </>
  );
};

MainForm.defaultProps = {
  regions: [],
  communes: [],
  extremeZones: [],
  companies: [],
  branchOffices: []
};

MainForm.propTypes = {
  regions: PropTypes.array,
  communes: PropTypes.array,
  extremeZones: PropTypes.array,
  companies: PropTypes.array,
  branchOffices: PropTypes.array,
  form: PropTypes.object.isRequired,
  onSelectChange: PropTypes.func.isRequired
};

export default MainForm;
