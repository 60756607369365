import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import Input from "../../../../components/Forms/Input";
import Select from "../../../../components/Forms/Select";

const CostRow = ({ cost, costCenters, onChange, index, onDelete, canEdit }) => {
  const [costName, setCostName] = useState(cost.name);
  const setCostCenterId = (el, costEl) => {
    costEl.name = el.label;
    setCostName(el.label);
    onChange({ target: { name: "name", value: el.label } }, index);
    onChange({ target: { name: "code", value: el.code } }, index);
  };
  return (
    <tr>
      <td>
        {canEdit ? (
          <Select
            key={`${index}-key`}
            label={"Centro de costo"}
            name={"name"}
            options={costCenters}
            value={costName}
            id={`${index}-cost_center`}
            onChange={el => setCostCenterId(el, cost, index)}
            required={true}
          />
        ) : (
          cost.name
        )}
      </td>
      <td>
        {canEdit ? (
          <Input
            id={`${index}-internal_order`}
            name="internal_order"
            value={cost.internal_order}
            onChange={e => onChange(e, index)}
            label="Orden Interna"
          />
        ) : (
          cost.internal_order || ""
        )}
      </td>
      <td>
        {canEdit ? (
          <Input
            id={`${index}-percentage`}
            name="percentage"
            value={cost.percentage}
            onChange={e => onChange(e, index)}
            label="%"
            type="number"
            step="any"
            max="100"
            min="0"
          />
        ) : (
          cost.percentage
        )}
      </td>
      {canEdit && (
        <td>
          <button
            className="btn btn-danger"
            onClick={() => onDelete(index)}
            type="button"
          >
            <i className="fe fe-trash" />
          </button>
        </td>
      )}
    </tr>
  );
}
CostRow.defaultProps = {
};

CostRow.propTypes = {
  cost: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired
};

export default CostRow;
