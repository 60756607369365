export const RESOLUTION_TIME = "resolution_time";
export const UNVALIDATED = "unvalidated";
export const UNCREATED = "uncreated";
export const REJECTED = "rejected";
export const FINISHED = "finished";

export const REPORT_TYPE_OPTIONS = [
  //{ label: "TIEMPO DE RESOLUCIÓN", value: RESOLUTION_TIME },
  { label: "FICHAS SIN VALIDACIÓN", value: UNVALIDATED },
  { label: "FICHAS SIN CREACIÓN", value: UNCREATED },
  { label: "FICHAS RECHAZADAS", value: REJECTED },
  { label: "FICHAS FINALIZADAS", value: FINISHED }
];
