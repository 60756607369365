import React, { useCallback } from "react";
import PropTypes from "prop-types";

const RequestStatusBadge = ({ status }) => {
  const setStylesClassName = useCallback(() => {
    if (status === "Creación") return "bg-danger";
    if (status === "Finalizado") return "bg-green";
    if (status === "Borrador") return "bg-info";
    return "bg-warning text-dark";
  }, [status]);

  return (
    <span className={`badge w-100 ${setStylesClassName()}`}>
      {status?.toString().toUpperCase()}
    </span>
  );
};

RequestStatusBadge.propTypes = {
  status: PropTypes.string.isRequired
};

export default RequestStatusBadge;
