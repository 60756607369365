import React, { useRef } from "react";
import PropTypes from "prop-types";
import InputMask from "react-input-mask";

const Input = ({ label, type, id, className, value, required, placeholder, mask, ...rest }) => {
  const inputRef = useRef(null)
  const handleMaskChange = e => {
    inputRef.current.value = e.target.value
  };

  const handleKeyDown = e => {
    if (type === "number" && (e.key === "-" || e.key === "e")) {
      e.preventDefault();
    }
  };

  const handleBlur = e => {
    if (type === "email") {
      if (e.target.value !== "") {
        const emailRegex = /\S+@\S+\.\S+/;
        if (!emailRegex.test(e.target.value)) {
          e.target.setCustomValidity("Email inválido");
        } else e.target.setCustomValidity("");
      } else e.target.setCustomValidity("");
    }
  };

  delete rest.inputType;
  return (
    <div className="form-floating">
      <input
        id={id}
        ref={inputRef}
        className={className}
        type={type}
        onBlur={handleBlur}
        placeholder={placeholder || label}
        value={value || ""}
        required={required}
        onKeyDown={handleKeyDown}
        {...rest}
      />
      <label htmlFor={id}>
        {required ? "(*)" : ""} {label}
      </label>
    </div>
  );
};

Input.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.oneOf([null])
  ]),
  required: PropTypes.bool,
  mask: PropTypes.string,
};

Input.defaultProps = {
  id: "input",
  type: "text",
  className: "form-control",
  value: "",
  required: false,
  mask: "",
};

export default Input;
