import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import Select from "react-select";

const SelectInput = ({
  required,
  label,
  value,
  options,
  placeholder,
  disabled,
  onChange,
  ...rest
}) => {

  const selectOptions = useMemo(() => {
    // if(options.length <= 0) return ([{label: value, value: ""}])
    // const currLabel = options.find(op => op.label == value)
    // const currValue = options.find(op => op.value == value)
    // if(!currLabel && !currValue) options.push({label: value, value: ""})
    return options
  }, [options])

  const selectedValue = useMemo(() => {
    if(!value) return ""
    const opt = options.find(op => op.value == value || op.label == value);
    return opt || {label: "Seleccionar", value: ""};
  }, [value, options]);

  return (
    <>
      {label ? (
        <label className="form-label">
          {required ? "(*)" : ""} {label}
        </label>
      ) : (
        ""
      )}
      <Select
        {...rest}
        onChange={(e, o) => {
          onChange(e, o, label, e);
        }}
        value={selectedValue}
        placeholder={label || placeholder}
        options={options}
        isDisabled={disabled}
      />
      {required && (
        <input
          tabIndex={-1}
          autoComplete="off"
          style={{ opacity: 0, height: 0, padding: 0, margin: 0 }}
          value={value || ""}
          required={required}
          onChange={() => {}}
        />
      )}
    </>
  );
};

SelectInput.defaultProps = {
  label: "Seleccione",
  value: "",
  required: false,
  options: [],
  placeholder: "Seleccione",
  disabled: false,
  asText: ""
};

SelectInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOf([null]),
    PropTypes.number
  ]),
  required: PropTypes.bool,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  asText: PropTypes.string
};

export default SelectInput;
