import React from "react";
import { FINISHED, RESOLUTION_TIME } from "../constants";

const daysDifference = (day1, day2) => {
  if (!day1 || !day2) return "-";
  return Math.floor((new Date(day1) - new Date(day2)) / (24 * 60 * 60 * 1000));
};

/* eslint-disable import/prefer-default-export */
export const daysCounterByType = (row, type) => {
  let daysCounter = daysDifference(new Date(), row.application_date);
  let textColor = "text-red";
  if ([RESOLUTION_TIME, FINISHED].includes(type)) {
    daysCounter = daysDifference(row.creation_date, row.application_date);
    textColor = "text-green";
  }
  return <span className={textColor}>{daysCounter}</span>;
};
