import React, { useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import Paginator from "../../../components/Pagination";
import RequestRow from "./RequestRow";
import SearchFilter from "./SearchFilter";

const Index = ({
  pagination,
  requests,
  user,
  params,
  applicants,
  branchOffices
}) => {
  const pending = useRef(params.pending ? params.pending === "true" : user.role == "applicant");

  const handleGetParameter = (type, value) => {
    const searcher = new URLSearchParams(window.location.search.substr(1));
    searcher.set(type, value);
    window.location.search = searcher.toString();
  };

  const handleCheckboxChange = event => {
    const {
      target: { name, checked }
    } = event;
    const searcher = new URLSearchParams(window.location.search.substr(1));
    searcher.set(name, checked);
    searcher.set("page", 1);
    window.location.search = searcher.toString();
  };

  const isApplicant = useMemo(() => user?.role == "applicant", [user]);

  return (
    <div className="card p-2">
      <div className="row g-2">
        <div className="col-xs-12 col-md-4 col-sm-5">
          <SearchFilter
            params={params}
            applicants={applicants}
            branchOffices={branchOffices}
          />
        </div>
        {isApplicant && (
          <div className="col-xs-12 col-md-3 offset-md-5 col-sm-5 offset-sm-2">
            <a
              href="/requests/new"
              className="float-md-end float-xs-start btn btn-outline-info w-100"
            >
              <i className="fe fe-user-plus pr-1" />
              Nueva solicitud
            </a>
          </div>
        )}
      </div>
      <div className="d-flex justify-content-between mt-3 mb-1">
        <h4 className={"text-black"}>Solicitudes de Inclusión</h4>
        <h4 className="form-check form-switch">
          <input
            className={`form-check-input ${pending.current && "bg-green"}`}
            type="checkbox"
            name="pending"
            checked={pending.current}
            onChange={handleCheckboxChange}
          />
        </h4>
      </div>
      <div className="table-responsive">
        <table className="table table-hover table-outline table-vcenter">
          <thead>
            <tr>
              <th>ID</th>
              <th>Fecha Solicitud</th>
              <th>Solicitante</th>
              <th>Empresa</th>
              <th>Sucursal</th>
              <th>Candidato</th>
              <th>Cargo</th>
              <th>Comentarios</th>
              <th>Estado</th>
              <th>Validado por</th>
              <th>Fecha Validación</th>
              <th>N° Persona</th>
              <th>Contrato</th>
              <th>Creado por</th>
              <th>Fecha creación</th>
              <th>Contador días</th>
              <th>Fin</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {requests.map(request => (
              <RequestRow request={request} key={request.id} user={user} />
            ))}
          </tbody>
        </table>
        <Paginator
          pagination={pagination}
          setGetParameter={handleGetParameter}
        />
      </div>
    </div>
  );
};

Index.defaultProps = {
  applicants: [],
  branchOffices: []
};

Index.propTypes = {
  pagination: PropTypes.object.isRequired,
  requests: PropTypes.array.isRequired,
  params: PropTypes.object.isRequired,
  applicants: PropTypes.array,
  branchOffices: PropTypes.array
};

export default Index;
