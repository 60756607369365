import axios from "axios";

const config = {
  headers: {
    "content-type": "multipart/form-data"
  }
};

export const createCompany = (params, csrf) => {
  axios.defaults.headers.common["X-CSRF-Token"] = csrf;

  return axios.post("/companies.json", params, config);
};

export const updateCompany = (companyId, params, csrf) => {
  axios.defaults.headers.common["X-CSRF-Token"] = csrf;

  return axios.put(`/companies/${companyId}.json`, params, config);
};
