import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useReactToPrint } from 'react-to-print';
import { toast } from 'react-toastify';
import Input from '../../../components/Forms/Input';
import {
  createPerson,
  generalistRevision,
  updateStatus,
} from '../../../services/requests';
import { STATUS_ACCEPTED_BY_MASTER_DATA } from './constants';
import PersonAcceptationStatus from './PersonAcceptationStatus';
import Consumer from '../../../channels/consumer';
import { Button } from 'react-bootstrap';

const MasterDataForm = ({
  personAcceptation,
  isAdmin,
  form,
  user,
  company,
  handleInputChange,
  setRequest,
  formRef,
  csrf
}) => {
  const [personNumber, setPersonNumber] = useState(form.person_number);
  const [acceptationRequest, setAcceptationRequest] = useState(
    form.acceptation_request
  );
  const [message, setMessage] = useState(form.message);
  const [loadingPersonNumber, setLoadingPersonNumber] = useState({
    accept: false,
    reject: false
  });
  Consumer.subscriptions.create(
    {
      channel: 'PersonAcceptationChannel',
      request_id: form.id,
    },
    {
      received: ({ person_number, message, status }) => {
        setPersonNumber(person_number);
        setAcceptationRequest(true);
        setMessage(message);
      },
    }
  );
  useEffect(() => {
    const actualConsumer = Consumer.subscriptions.subscriptions.find(
      (s) => JSON.parse(s.identifier).channel == 'PersonAcceptationChannel'
    );
    return () => actualConsumer?.unsubscribe();
  }, []);

  const { id } = form;
  const [btnCreateDisabled, setBtnCreateDisabled] = useState(false);
  const [personLoading, setPersonLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const handleOnPrint = useReactToPrint({
    content: () => formRef.current,
    copyStyles: true,
    documentTitle: 'Solicitud de inclusión',
  });

  const btnCreateCallback = useCallback((node) => {
    setBtnCreateDisabled(node?.disabled);
  });

  const createFormData = () => {
    const { id: userId } = user;
    const formData = new FormData();
    formData.append('request[person_number]', form.person_number);
    formData.append('request[position_number]', form.position_number);
    formData.append('request[creator_id]', userId);
    formData.append('request[creation_date]', new Date());
    formData.append('request[status]', STATUS_ACCEPTED_BY_MASTER_DATA);
    if (form.contract) formData.append('request[contract]', form.contract);
    return formData;
  };

  const handleOnAccept = (e) => {
    e.preventDefault();
    setLoading(true);
    if (confirm('¿Seguro quieres aprobar la solicitud?')) {
      toast.promise(
        updateStatus(id, createFormData(), csrf),
        {
          pending: "Cargando...",
          success: {
            render: () => `Persona creada con éxito!`,
            onClose: setTimeout(() => (window.location = '/requests'), 2000),
          },
          error: "Ocurrió un error realizando la acción."
        },
        {
          autoClose: 2000,
          onClose: () => setLoading(false),
        }
      );
    }
  };

  const handleSubmit = (params, name) => {
    setLoadingPersonNumber({ ...loadingPersonNumber, [name]: true });
    const formData = new FormData();
    formData.append("request[person_number]", form.person_number);
    formData.append("request[message]", form.message);
    Object.keys(params).forEach(key => {
      formData.append(`request[${key}]`, params[key]);
    });
    toast.promise(
      updateStatus(id, formData, csrf),
      {
        pending: "Cargando...",
        success: {
          render({ data }) {
            setTimeout(() => window.location = "/requests", 2000);
            return `Se ha valdiado la solicitud de inclusión`;
          }
        },
        error: "Ocurrió un error realizando la acción."
      },
      {
        autoClose: 2000,
        onClose: () => setLoadingPersonNumber({...loadingPersonNumber, [name]: false}),
        pauseOnHover: false
      }
    );
  };

  const handleOnPersonNumber = e => {
    e.preventDefault();
    if (form.person_number === "" || form.person_number === null) {
      alert("Debe ingresar un número de persona");
      return;
    }
    if (confirm("¿Seguro(a) que quieres ingresar número de Persona?")) {
      handleSubmit(
        {
          creator_id: user.id,
          validation_date: new Date()
        },
        "accept"
      );
    }
  };

  const handleOnReject = e => {
    e.preventDefault();
    if (confirm("¿Seguro que quieres rechazar la solicitud?")) {
      handleSubmit({ status: STATUS_REJECTED_BY_VALIDATOR }, "reject");
    }
  };

  const createPersonSuccess = (response) => {
    // Acá setear person_number según respuesta de la API
    setError(false);
    setRequest({
      ...form,
      acceptation_request: true,
    });
  };

  const revisionSuccess = (response) => {
    // Acá setear person_number según respuesta de la API
    setError(false);
    setRequest({
      ...form,
      acceptation_request: false,
    });

    window.location;
  };

  const onCreatePerson = () => {
    setPersonLoading(true);
    createPerson(id)
      .then(
        (response) => createPersonSuccess(response),
        (err) => setError({ message: err.response.data })
      )
      .finally(() => setPersonLoading(false));
  };

  const onGeneralistRevision = () => {
    if (confirm('¿Seguro quieres enviar a revisión?')) {
      toast.promise(
        generalistRevision(id),
        {
          pending: 'Cargando...',
          success: {
            render: () => `Persona enviada a revisión por Generalista!`,
            onClose: setTimeout(() => (window.location = '/requests'), 2000),
          },
          error: 'Ocurrió un error realizando la acción.',
        },
        {
          autoClose: 2000,
          onClose: () => setLoading(false),
        }
      );
    }
  };

  const handleFileChange = (event) => {
    const {
      target: {
        files: [contract],
      },
    } = event;
    setRequest({ ...form, contract });
  };

  return (
    <div className='card'>
      <form className='card-body row g-2' onSubmit={handleOnAccept}>
        <div className='col-12'>
          <button
            type='button'
            className='btn btn-outline-primary float-right'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-target='print'
            title='Imprimir'
            onClick={handleOnPrint}
          >
            <i className='fe fe-printer' />
          </button>
        </div>

        <div className='col-12 p-0'>
          <Input
            type='number'
            id='position_number'
            name='position_number'
            className='form-control'
            value={form.position_number || ''}
            onChange={handleInputChange}
            label='N° de Posición'
            readOnly={'readonly'}
          />
        </div>

        {isAdmin && (
          <>
            <div className='col-12 p-0'>
              <Input
                type='number'
                id='person_number'
                name='person_number'
                className='form-control'
                value={form.person_number || ''}
                onChange={handleInputChange}
                label='N° de Persona'
              />
              <Input
                type="text"
                id="message"
                name="message"
                className="form-control"
                onChange={handleInputChange}
                label="Mensaje"
                required={!form.person_number}
              />
            </div>
            <div className='col-12 mt-5 g-1 p-0 d-flex justify-content-xs-between justify-content-around'>
              <button
                type="submit"
                className='btn btn-outline-success'
                onClick={handleOnPersonNumber}
                disabled={loadingPersonNumber.accept}
          >
            {loadingPersonNumber.accept && (
              <span
                className="spinner-border spinner-border-sm mr-1"
                role="status"
                aria-hidden="true"
              />
            )}
            <i className="fe fe-check" />
            Guardar N° Persona
              </button>
            </div>
          </>
        )}

        {
          <>
            {error && (
              <div className='alert alert-danger'>
                Ocurrió un error al procesar la solicitud: {error.message}
              </div>
            )}
            <button
              ref={btnCreateCallback}
              type="button"
              role="button"
              className="btn btn-outline-success col-12"
              onClick={onCreatePerson}
              disabled={
                form.acceptation_request || personNumber || personLoading
              }
            >
              {personLoading && (
                <span
                  className="spinner-border spinner-border-sm mr-1"
                  role="status"
                  aria-hidden="true"
                />
              )}
              Crear Persona
            </button>
            {personNumber >= 0 && acceptationRequest && (
              <PersonAcceptationStatus
                personNumber={personNumber}
                message={message}
              />
            )}
            <Button onClick={onGeneralistRevision} variant="warning">
              Revisión Generalista
            </Button>
          </>
        }

        <div className='input-group p-0'>
          <label className='input-group-text text-blue' htmlFor='contract'>
            Contrato
          </label>
          <input
            type='file'
            className='form-control'
            id='contract'
            name='contract'
            onChange={handleFileChange}
            required={company?.contract && !form.contract_url}
            title='cac'
            accept='.pdf, .word, .doc, .docx'
          />
        </div>

        <div className='col-12 mt-5 g-1 d-flex justify-content-center'>
          <button
            type='submit'
            className='btn btn-outline-success'
            disabled={!personNumber || personNumber === 0}
          >
            {loading && (
              <span
                className='spinner-border spinner-border-sm mr-1'
                role='status'
                aria-hidden='true'
              />
            )}
            <i className='fe fe-check' />
            Aceptar
          </button>
        </div>
      </form>
    </div>
  );
};

export default MasterDataForm;
