import React from "react";
import PropTypes from "prop-types";
import CostRow from "./CostRow";
import { useMemo } from "react";

const CostDistribution = ({
  form,
  costCenters,
  costs,
  onInputChange,
  onAddCost,
  onDelete,
  canEdit
}) => {
  const costCentersOptions = useMemo(() => {
    if (!form.branch_office_id) return costCenters;
    const options = [];
    costCenters.forEach(costCenter => {
      if (costCenter.branch_office_id == form.branch_office_id) options.push(costCenter);
    });
    return options;
  }, [form.branch_office_id]);

  return (
    <div className="card">
      <div className="card-header d-flex justify-content-between align-items-center">
        <h3 className="card-title">Centro de Costos</h3>
        {canEdit && (
          <button
            className="btn btn-outline-info"
            type="button"
            onClick={onAddCost}
          >
            <i className="fe fe-plus pr-1" />
            Centro de costo
          </button>
        )}
      </div>
      <div className="card-body row">
        <div className="">
          <table className="table table-hover table-outline table-vcenter">
            <thead>
              <tr>
                <th>Centro Costo</th>
                <th>Orden Interna</th>
                <th>Porcentaje (%)</th>
                {canEdit && <th>Acciones</th>}
              </tr>
            </thead>
            <tbody>
              {costs.map((cost, idx) => {
                if (cost._destroy === true) return null;
                return (
                  <CostRow
                    cost={cost}
                    costCenters={costCentersOptions}
                    index={idx}
                    onChange={onInputChange}
                    onDelete={onDelete}
                    canEdit={canEdit}
                    key={idx}
                  />
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
};

CostDistribution.defaultProps = {
  costs: []
};

CostDistribution.propTypes = {
  onInputChange: PropTypes.func.isRequired,
  onAddCost: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  costs: PropTypes.array
};

export default CostDistribution;
