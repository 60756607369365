import React from "react";

const FilesForm = ({
  filesMaintainers,
  files,
  onInputChange,
  onDestroyFile,
  onEditFile,
  canEdit
}) => {
  return (
    <div className="card">
      <div className="p-4 border-bottom">
        <h4 className="card-title">Archivos adjuntos</h4>
      </div>
      <div className="card-body row">
        {filesMaintainers.map(file => {
          const requestFile = files.find(f => f.file_maintainer_id == file.id);
          if (requestFile?.public_url && !requestFile.editing)
            return (
              <div
                className="row d-flex justify-content-between mb-2" key={file.id}
              >
                <span className="col-lg-6 col-xs-12">
                  Archivo: <b>{file.name}</b>
                </span>
                <div className="col-lg-6 col-xs-12">
                  <div className="float-lg-right">
                    <a
                      href={requestFile.public_url}
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-target={`#${file.id}-download`}
                      title="Descargar"
                      className="btn btn-outline-info mr-1"
                      target="_blank"
                    >
                      <i className="fe fe-download" />
                      <span className="large-screen-button-text">
                        Descargar
                      </span>
                    </a>
                    {canEdit && (
                      <>
                        <button
                          type="button"
                          className="btn btn-outline-success mr-1"
                          onClick={() => onEditFile(file.id)}
                          title="Editar archivo"
                        >
                          <i className="fe fe-edit" />
                          <span className="large-screen-button-text">
                            Editar
                          </span>
                        </button>
                        <button
                          type="button"
                          className="btn btn-outline-danger"
                          onClick={() => onDestroyFile(file.id)}
                          title="Eliminar archivo"
                        >
                          <i className="fe fe-trash" />
                          <span className="large-screen-button-text">
                            Eliminar
                          </span>
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            );
          if (!canEdit) return null;

          return (
            <div className="d-flex mb-2" key={file.id}>
              <div className="input-group" key={file.id}>
                <label className="input-group-text" htmlFor={`file-${file.id}`}>
                  {file.name}
                </label>
                <input
                  type="file"
                  className="form-control"
                  id={`file-${file.id}`}
                  onChange={e => onInputChange(e, file.id)}
                  required={requestFile ? false : file.required}
                  disabled={!canEdit}
                />
              </div>
              {requestFile?.public_url && (
                <button
                  type="button"
                  className="btn btn-outline-danger"
                  onClick={() => onEditFile(file.id, false)}
                  title="Cancelar"
                >
                  <i className="fe fe-x mr-1" />
                </button>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FilesForm;
