import React, { useMemo } from "react";
import PropTypes from "prop-types";
import Input from "../../../../components/Forms/Input";
import Select from "../../../../components/Forms/Select";
import InputInfo from "../../../../components/Layouts/InputInfo";
import { DateFormat } from "../../../../components/Utils";

const AcademicForm = ({
  form,
  onSelectChange,
  onInputChange,
  educationalLevels,
  countries,
  qualifiedLevels,
  specialties,
  canEdit
}) => {
  const specialtiesOptions = useMemo(() => {
    if (!form.educational_level_id) return specialties;
    return specialties.filter(
      p => p.educational_level_id == form.educational_level_id
    );
  }, [form.educational_level_id]);

  const columns = useMemo(
    () => [
      {
        inputType: "selector",
        label: "Nivel educacional",
        attribute_name: "educational_level_id",
        options: educationalLevels,
        required: true,
        value: f =>
          educationalLevels.find(el => el.value == f.educational_level_id)
            ?.label
      },
      {
        inputType: "selector",
        label: "Especialidad",
        attribute_name: "specialty_id",
        options: specialtiesOptions,
        required: false,
        value: f =>
          specialties.find(el => el.value == f.specialty_id)?.label
      },
      {
        inputType: "input",
        type: "text",
        label: "Institución",
        attribute_name: "educational_institution",
        required: true,
        value: f => f.educational_institution
      },
      {
        inputType: "selector",
        label: "País de estudios",
        attribute_name: "country_id",
        options: countries,
        required: true,
        value: f => countries.find(c => c.value == f.country_id)?.label
      },
      {
        inputType: "input",
        type: "text",
        label: "Título",
        attribute_name: "degree",
        required: true,
        value: f => f.degree
      }
    ],
    [
      form.educational_level_id,
      form.educational_institution,
      form.country_id,
      form.validity_from,
      form.validity_to,
      form.qualified_level_id,
      form.degree,
      form.specialty_id
    ]
  );

  return (
    <div className="card">
      <div className="p-4 border-bottom">
        <h4 className="card-title">Formación Académica</h4>
      </div>
      <div className="card-body row g-3">
        {columns.map(column => {
          const { attribute_name, label, inputType, value, ...rest } = column;
          if (!canEdit) {
            return <InputInfo key={label} title={label} value={value(form) || form[attribute_name]} />;
          }
          if (inputType === "selector")
            return (
              <Select
                key={attribute_name}
                label={label}
                value={form[attribute_name]}
                name={attribute_name}
                id={attribute_name}
                onChange={onSelectChange}
                {...rest}
              />
            );
          return (
            <Input
              key={attribute_name}
              value={form[attribute_name]}
              name={attribute_name}
              id={attribute_name}
              onChange={onInputChange}
              label={label}
              {...rest}
            />
          );
        })}
      </div>
    </div>
  );
};

AcademicForm.defaultProps = {
  qualifiedLevels: [],
  countries: [],
  specialties: []
};

AcademicForm.propTypes = {
  qualifiedLevels: PropTypes.array,
  countries: PropTypes.array,
  specialties: PropTypes.array,
  form: PropTypes.object.isRequired,
  onSelectChange: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired
};

export default AcademicForm;
