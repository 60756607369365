import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { numberFormat } from '../Utils';
import Select from 'react-select';
import InputInfo from '../Layouts/InputInfo';
import PeriodicityInput from './PeriodicityInput';
import SelectInput from './Select';
import BonusRow from '../../containers/requests/Form/Bonus/BonusRow';
import AssignmentRow from './AssignmentRow';

const Assignments = ({
  requestAssignments,
  assignments,
  onAddAssignment,
  onDeleteAssignment,
  onInputAssignmentChange,
  onAssignmentSelectChange,
  canEdit,
}) => (
  <div className='card-body row'>
    {canEdit && (
      <button
        className='btn btn-outline-info'
        type='button'
        onClick={onAddAssignment}
      >
        <i className='fe fe-plus pr-1' />
        Asignación
      </button>
    )}
    <div className=''>
      <table className='table table-hover table-outline table-vcenter'>
        <thead>
          <tr>
            <th>Asignación</th>
            <th>Tipo</th>
            <th>Periodo</th>
            <th>Monto</th>
            {canEdit && <th>Acciones</th>}
          </tr>
        </thead>
        <tbody>
          {requestAssignments.map((requestAssignment, idx) => {
            if (requestAssignment._destroy === true) return null;
            return (
              <AssignmentRow
                assignments={assignments}
                canEdit={canEdit}
                requestAssignment={requestAssignment}
                index={idx}
                onChange={onInputAssignmentChange}
                onSelectChange={onAssignmentSelectChange}
                onDelete={onDeleteAssignment}
                key={idx + 12}
              />
            );
          })}
        </tbody>
      </table>
    </div>
  </div>
);

Assignments.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  assignmentType: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.oneOf([null]),
  ]),
  required: PropTypes.bool,
};

Assignments.defaultProps = {
  id: 'input',
  type: 'text',
  className: 'form-control',
  value: '',
  assignmentType: '',
  required: false,
};

export default Assignments;
