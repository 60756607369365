import React, { useMemo } from "react";

const Filters = ({ filters, setFilters, companies, branchOffices }) => {
  const handleInputChange = event => {
    const {
      target: { name, value }
    } = event;
    setFilters({
      ...filters,
      [name]: value,
      ...(name === "company_id" ? { branch_office_id: "" } : {})
    });
  };

  const branchOfficesOptions = useMemo(() => {
    if (!filters.company_id) return branchOffices;
    return branchOffices.filter(b => b.company_id == filters.company_id);
  }, [filters.company_id]);

  return (
    <div className="col-xs-12 col-md-9 offset-md-3 d-flex justify-content-md-around flex-column flex-md-row">
      <div className="row w-100">
        <div className="col-12 col-md-3">
          <label htmlFor="search-id" className="form-label">
            Empresa
          </label>
          <select
            className="form-control w-100"
            id="search-company_id"
            placeholder=""
            onChange={handleInputChange}
            name="company_id"
            value={filters.company_id}
          >
            <option value="">Seleccionar</option>
            {companies.map(applicant => (
              <option value={applicant.value} key={applicant.value}>
                {applicant.label}
              </option>
            ))}
          </select>
        </div>
        <div className="col-12 col-md-3">
          <label htmlFor="search-id" className="form-label">
            Sucursal
          </label>
          <select
            className="form-control"
            id="search-branch_office_id"
            onChange={handleInputChange}
            name="branch_office_id"
            value={filters.branch_office_id}
          >
            <option value="">Seleccionar</option>
            {branchOfficesOptions.map(applicant => (
              <option value={applicant.value} key={applicant.value}>
                {applicant.label}
              </option>
            ))}
          </select>
        </div>
        <div className="col-12 col-md-3">
          <label htmlFor="search-id" className="form-label">
            Desde
          </label>
          <input
            className="form-control"
            id="search-application_date_from"
            onChange={handleInputChange}
            name="application_date_from"
            value={filters.application_date_from}
            type="date"
          />
        </div>
        <div className="col-12 col-md-3">
          <label htmlFor="search-id" className="form-label">
            Hasta
          </label>
          <input
            className="form-control"
            id="search-application_date_to"
            onChange={handleInputChange}
            name="application_date_to"
            value={filters.application_date_to}
            type="date"
          />
        </div>
      </div>
    </div>
  );
};

export default Filters;
