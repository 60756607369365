export const percentageFormat = number => {
  if (!number) return 0;
  return (Math.round(number * 100) / 100).toFixed(2);
};

export const colours = [
  "#1D0ABF",
  "#346F43",
  "#9B086A",
  "#0ABABF",
  "#F9C3AF",
  "#B2BF68",
  "#EE1616",
  "#38487C",
  "#DAE527",
  "#27E566",
  "#A46401",
  "#BF830A",
  "#83FDFF",
  "#B2C8E3",
  "#0BB27F",
  "#E39DFB"
];
