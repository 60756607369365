import axios from "axios";

const config = {
  headers: {
    "content-type": "multipart/form-data"
  }
};

export const createRequest = (params, csrf) => {
  axios.defaults.headers.common["X-CSRF-Token"] = csrf;

  return axios.post("/requests.json", params);
};

export const updateRequest = (requestId, params, csrf) => {
  axios.defaults.headers.common["X-CSRF-Token"] = csrf;

  return axios.put(`/requests/${requestId}.json`, params, config);
};

export const createPerson = id => {
  // Cambiar esta URL cuando AGUNSA envíe el endpoint correspondiente
  const URL = `/requests/${id}/create_person.xml`;
  // Cambiar HTTP ACTION según endpoint de AGUNSA
  return axios.get(URL);
};

export const generalistRevision = id => {
  const URL = `/requests/${id}/generalist_revision`;
  // Cambiar HTTP ACTION según endpoint de AGUNSA
  return axios.get(URL);
};

export const updateStatus = (requestId, params, csrf) => {
  axios.defaults.headers.common["X-CSRF-Token"] = csrf;

  return axios.put(`/requests/${requestId}/update_status.json`, params, config);
};

export const getFormPdf = id => axios.get(`/requests/${id}/form_pdf.pdf`);

export const getComments = (requestId, csrf) => {
  axios.defaults.headers.common["X-CSRF-Token"] = csrf;

  return axios.get(`/requests/${requestId}/comments.json`);
};

export const getRequests = params => {
  const url = `/reports.json${params}`;

  return axios.get(url);
}

export const deleteRequest = ( requestId, csrf) => {
  axios.defaults.headers.common["X-CSRF-Token"] = csrf;

  return axios.delete(`/requests/${requestId}`);
};

export const createComment = (requestId, params, csrf) => {
  axios.defaults.headers.common["X-CSRF-Token"] = csrf;

  return axios.post(`/requests/${requestId}/comments.json`, params);
};

export const updateComment = (id, requestId, params, csrf) => {
  axios.defaults.headers.common["X-CSRF-Token"] = csrf;

  return axios.put(`/requests/${requestId}/comments/${id}.json`, params);
};

export const deleteComment = (id, requestId, params, csrf) => {
  axios.defaults.headers.common["X-CSRF-Token"] = csrf;

  return axios.delete(`/requests/${requestId}/comments/${id}.json`, params);
};

export const updateCommentNotification = (requestId, commentId, id) => {
  const url = `/requests/${requestId}/comments/${commentId}/notifications/${id}.json`;
  return axios.put(url);
};
