import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { numberFormat } from "../Utils";
import Select from "react-select";

const MoneyInput = ({
  assignmentType,
  label,
  type,
  id,
  className,
  value,
  required,
  onChange,
  onSelectChange,
  name,
  ...rest
}) => {
  const [inputValue, setInputValue] = useState(value || "");
  const ref = useRef();

  const handleOnChange = event => {
    const {
      target: { name, value: newValue }
    } = event;
    onChange({ value: newValue?.replace(/[^0-9,]/g, "") }, { name }, label, {
      label: newValue
    });
  };

  useEffect(() => {
    setInputValue(numberFormat(value));
  }, [value]);

  return (
    <div className="form-floating">
      <input
        ref={ref && ref?.current?.focus()}
        id={id}
        name={name}
        className={className}
        placeholder={label}
        value={inputValue}
        required={required}
        onChange={handleOnChange}
        {...rest}
      />
      <label htmlFor={id}>
        {required ? "(*)" : ""} {label} ($)
      </label>
    </div>
  );
};

MoneyInput.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  assignmentType: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.oneOf([null])
  ]),
  required: PropTypes.bool
};

MoneyInput.defaultProps = {
  id: "input",
  type: "text",
  className: "form-control",
  value: "",
  assignmentType: "",
  required: false,
};

export default MoneyInput;
