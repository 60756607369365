import React from "react";
import PropTypes from "prop-types";
import Input from "../../../../components/Forms/Input";

const SchedulePlanRow = ({ schedulePlan, onChange, index, onDelete, canEdit }) => (
    <tr>
        <td>
            {canEdit ? (
                <Input
                    id={`${index}-schedule_plan`}
                    name="name"
                    value={schedulePlan.name}
                    onChange={e => onChange(e, index)}
                    required
                    label="Plan Horario"
                />
            ) : (
                schedulePlan.name
            )}
        </td>
        {canEdit && (
            <td>
                <button
                    className="btn btn-danger"
                    onClick={() => onDelete(index)}
                    type="button"
                >
                    <i className="fe fe-trash" />
                </button>
            </td>
        )}
    </tr>
);

SchedulePlanRow.defaultProps = {};

SchedulePlanRow.propTypes = {
    schedulePlan: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired
};

export default SchedulePlanRow;