import React, { useRef, useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import PropTypes from "prop-types";
import Input from "../../components/Forms/Input";
import CheckboxInput from "../../components/Forms/CheckboxInput";
import Select from "../../components/Forms/Select";
import Table from "../../components/Table";
import Errors from "../../components/Layouts/Errors";
import * as XLSX from "xlsx";
import { createEntities, updateEntity } from "../../services/entities";

const MassiveLoad = ({
  entity,
  modalId,
  csrf,
  columns,
  entityName,
  entityClass,
}) => {
  const [form, setForm] = useState(entity);
  const [errors, setErrors] = useState([]);
  const [entities, setEntities] = useState([]);

  const handleSubmitSuccess = () => {
    const { id } = form;
    alert(`Mantenedor ${id ? "actualizado" : "creado"} con éxito!`);
    window.location.reload();
  };

  const createMassiveEntity = () => {
    const { id } = form;
    const entityClass = new URLSearchParams(window.location.search).get(
      "entity_class"
    );
    return createEntities(form, csrf, entityClass);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    createMassiveEntity(form, csrf).then(
      () => handleSubmitSuccess(),
      (err) => setErrors(err.response.data)
    );
  };

  const handleInputChange = (e) => {
    const {
      target: { files },
    } = e;
    const [file] = files;
    const reader = new FileReader();

    reader.onload = (evt) => {
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const cleanData = XLSX.utils.sheet_to_json(ws)
      const hasId = !!cleanData[0]["ID"]
      const table_columns = columns.map(c => c.attribute_name);
      const entities_columns = columns.map((c) => c.options ? `${c.attribute_name}_id` : c.attribute_name);
      if(hasId) table_columns.unshift("ID")
      if(hasId) entities_columns.unshift("id")
      const data = XLSX.utils.sheet_to_json(ws, { header: table_columns }).slice(1);
      const entitiesSheet = XLSX.utils.sheet_to_json(ws, { header: entities_columns }).slice(1);

      setEntities(data);
      setForm(entitiesSheet);
    };
    reader.readAsBinaryString(file);
  };

  return (
    <div
      className="modal fade"
      id={modalId}
      tabIndex="-1"
      aria-labelledby={`${modalId}Label`}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id={`${modalId}Label`}>
              {entityName}
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body">
            <form className="row" onSubmit={handleSubmit}>
              <div className="col-12">
                <Errors errors={errors} />
              </div>
              <div className="col-12">
                <div className="row g-2">
                  <Form.Group controlId="formFileSm" className="mb-3">
                    <Form.Label>Archivo de carga</Form.Label>
                    <Form.File
                      accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                      size="sm"
                      onChange={handleInputChange}
                      form
                    />
                  </Form.Group>
                </div>
                <div className="row">
                  <Table
                    key={"loadMassiveTable"}
                    rows={entities}
                    columns={columns}
                    entityClass={entityClass}
                    isEntity
                    editModal
                  />
                </div>
                <div className="row">
                  <div className="col-xs-12 col-md-3 col-lg-4 d-flex align-items-center">
                    <div className="btn-group-vertical w-100">
                      <button type="submit" className="btn btn-outline-success">
                        Guardar
                      </button>
                      <button
                        type="button"
                        className="btn btn-outline-warning mt-2"
                        data-bs-dismiss="modal"
                      >
                        Cancelar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

MassiveLoad.propTypes = {
  company: PropTypes.object,
  modalId: PropTypes.string.isRequired,
  csrf: PropTypes.string.isRequired,
};

MassiveLoad.defaultProps = {
  company: {},
};

export default MassiveLoad;
