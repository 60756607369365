/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

require("rails-ujs").start();
require("turbolinks").start();

import * as bootstrap from "bootstrap";
import "../src/stylesheets";
import "../src/images";
import $ from "jquery";

// Support component names relative to this directory:
var componentRequireContext = require.context("containers", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

$(function() {
  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  tooltipTriggerList.map((tooltipTrigger) => {
    return new bootstrap.Tooltip(tooltipTrigger);
  });
  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="collapse"]'))
  tooltipTriggerList.map((tooltipTrigger) => {
    return new bootstrap.Collapse(tooltipTrigger);
  });
});
