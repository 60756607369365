import React, { useMemo } from "react";
import PropTypes from "prop-types";
import Input from "../../../../components/Forms/Input";
import Select from "../../../../components/Forms/Select";
import CheckboxGroup from "../../../../components/Forms/CheckboxGroup";
import InputInfo from "../../../../components/Layouts/InputInfo";
import { DateFormat } from "../../../../components/Utils";
import SchedulePlanRow from "./SchedulePlanRow";
import RutInput from "../../../../components/Forms/RutInput";

const ContractForm = ({
  form,
  onInputChange,
  onSelectChange,
  onRutChange,
  contractTypes,
  contractClasses,
  choreAddresses,
  attendanceMarks,
  timeTypes,
  schedulePlans,
  onAddSchedulePlan,
  onDeleteSchedulePlan,
  onInputSchedulePlanChange,
  canEdit
}) => {
  const choreAddressesOptions = useMemo(() => {
    if (!form.branch_office_id) return choreAddresses;
    const options = [];
    choreAddresses.forEach(choreAddress => {
      if (choreAddress.branch_office_id == form.branch_office_id) options.push(choreAddress);
    });
    return options;
  }, [form.branch_office_id]);
  const columns = useMemo(
    () => [
      {
        inputType: "input",
        type: "date",
        label: "Inicio de contrato",
        attribute_name: "contract_begin_date",
        required: true,
        value: f => DateFormat(f.contract_begin_date)
      },
      {
        inputType: "input",
        type: "date",
        label: "Término de contrato (Si es indefinido ingresar: 31.12.9999)",
        attribute_name: "contract_end_date",
        required: true,
        value: f => DateFormat(f.contract_end_date)
      },
      {
        inputType: "input",
        type: "date",
        label: "Fecha reconocimiento años de servicio",
        attribute_name: "service_recognition_date",
        value: f => DateFormat(f.service_recognition_date)
      },
      {
        inputType: "checkbox",
        label: "Tipo de contrato",
        attribute_name: "contract_type_id",
        required: true,
        options: contractTypes,
        value: f =>
          contractTypes.find(c => c.value == f.contract_type_id)?.label
      },
      {
        inputType: "selector",
        label: "Clase de contrato",
        attribute_name: "contract_class_id",
        options: contractClasses,
        required: true,
        value: f =>
          contractClasses.find(c => c.value == f.contract_class_id)?.label
      },
      {
        inputType: "input",
        type: "text",
        label: "Plan Horario",
        attribute_name: "time_plan",
        required: true,
        value: f => f.time_plan
      },
      {
        inputType: "selector",
        type: "text",
        options: choreAddressesOptions,
        label: "Dirección Ejecución de la Función",
        attribute_name: "chore_address_id",
        required: true,
        value: f => choreAddressesOptions.find(c => c.value == f.chore_address_id)?.label
      },
      {
        inputType: "rut",
        type: "text",
        label: "Rut empresa principal",
        attribute_name: "enterprise_identity",
        required: false,
        value: f => f.enterprise_identity
      },
      {
        inputType: "input",
        type: "text",
        label: "Dirección empresa principal",
        attribute_name: "enterprise_address",
        required: false,
        value: f => f.enterprise_address
      },
      {
        inputType: "selector",
        label: "Marca asistencia",
        attribute_name: "attendance_mark_id",
        options: attendanceMarks,
        required: true,
        value: f =>
          attendanceMarks.find(c => c.value == f.attendance_mark_id)?.label
      },
      {
        inputType: "selector",
        label: "Adic. Tiempo",
        attribute_name: "time_type_id",
        options: timeTypes,
        required: true,
        value: f => timeTypes.find(c => c.value == f.time_type_id)?.label
      },
      // {
      //   inputType: "input",
      //   type: "text",
      //   label: "N° Tarjeta de Asistencia",
      //   attribute_name: "attendance_card",
      //   required: false,
      //   value: f => f.attendance_card
      // }
    ],
    [
      form.branch_office_id,
      form.contract_type_id,
      form.contract_class_id,
      form.attendance_mark_id,
      form.time_type_id,
      form.contract_begin_date,
      form.contract_end_date,
      form.attendance_card,
      form.time_plan,
      form.chore_address_id,
      form.enterprise_identity,
      form.enterprise_address,
    ]
  );

  return (
    <div className="card">
      <div className="p-4 border-bottom">
        <h4 className="card-title">Datos contractuales</h4>
      </div>
      <div className="card-body row g-3">
        {columns.map(column => {
          const { textValue, attribute_name, value, label, ...rest } = column;
          
          if (attribute_name === "time_plan") {
            return (
              <div className="card-body row" key={`${attribute_name}_contr`}>
                {canEdit && (
                  <button
                    className="btn btn-outline-info"
                    type="button"
                    onClick={onAddSchedulePlan}
                  >
                    <i className="fe fe-plus pr-1" />
                    Plan Horario
                  </button>)}
                <div className="">
                  <table className="table table-hover table-outline table-vcenter">
                    <thead>
                      <tr>
                        <th>Plan Horario</th>
                        {canEdit && <th>Acciones</th>}
                      </tr>
                    </thead>
                    <tbody>
                      {schedulePlans.map((schedulePlan, idx) => {
                        if (schedulePlan._destroy === true) return null;
                        return (
                          <SchedulePlanRow
                            schedulePlan={schedulePlan}
                            index={idx}
                            onChange={onInputSchedulePlanChange}
                            onDelete={onDeleteSchedulePlan}
                            canEdit={canEdit}
                            key={idx}
                          />
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            )
          }
          if (!canEdit) {
            return <InputInfo key={label} title={label} value={value(form) || form[attribute_name]} />;
          }
          if (column.inputType === "selector")
            return (
              <Select
                key={attribute_name}
                label={label}
                value={form[attribute_name]}
                name={attribute_name}
                id={attribute_name}
                onChange={onSelectChange}
                {...rest}
              />
            );
          if (column.inputType === "checkbox")
            return (
              <CheckboxGroup
                key={label}
                value={form[attribute_name]}
                name={attribute_name}
                id={attribute_name}
                title={label}
                onChange={onSelectChange}
                {...rest}
              />
            );
          if (column.inputType === "rut")
            return (
              <RutInput
                key={attribute_name}
                label={label}
                value={form[attribute_name]}
                onChange={onRutChange}
                name={attribute_name}
                id={attribute_name}
                required={column.required}
              />
            );
          return (
            <Input
              key={attribute_name}
              value={form[attribute_name]}
              name={attribute_name}
              id={attribute_name}
              onChange={onInputChange}
              label={label}
              type={column.type}
              {...rest}
            />
          );
        })}
      </div>
    </div>
  );
};

ContractForm.defaultProps = {
  contractTypes: [],
  contractClasses: [],
  attendanceMarks: [],
  timeTypes: []
};

ContractForm.propTypes = {
  contractTypes: PropTypes.array,
  contractClasses: PropTypes.array,
  attendanceMarks: PropTypes.array,
  timeTypes: PropTypes.array,
  form: PropTypes.object.isRequired,
  onSelectChange: PropTypes.func.isRequired
};

export default ContractForm;
