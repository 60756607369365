import React, { useCallback, useMemo } from "react";
import Select from "react-select";
import InputInfo from "../Layouts/InputInfo";
import PropTypes from "prop-types";

const PeriodicityInput = ({ labelTop, canEdit, id, name, onSelectChange, required, assignmentType, ...rest }) => {
    const assignments_options = [
        { label: "Diaria", value: "daily" },
        { label: "Mensual", value: "monthly" }
    ]
    const selectedValue = useMemo(() => {
        if (!assignmentType) return null;
        return assignments_options.find((el) => el.value == assignmentType)
    }, [assignmentType])

    const label = <label className={"form-label"}>
        {required ? "(*)" : ""} {"Diaria/Mensual"}
    </label>
    return (
        canEdit ? (
            <>
                {labelTop && label}
                <Select
                    {...rest}
                    placeholder={"Seleccionar"}
                    options={assignments_options}
                    name={`${name}_type`}
                    onChange={onSelectChange}
                    required={required}
                    id={`${id}_type`}
                    value={selectedValue}
                />
                {!labelTop && label}

            </>
        ) : (
            <InputInfo key={`${id}_type_label`} title={"Diaria/Mensual"} value={assignments_options.find((el) => el.value == assignmentType)} />
        )
    )
}

PeriodicityInput.propTypes = {
    required: PropTypes.bool,
    labelTop: PropTypes.bool,
}
PeriodicityInput.defaultProps = {
    required: true,
    labelTop: false,
}
export default PeriodicityInput;