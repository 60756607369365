import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Input from '../../../../components/Forms/Input';
import SelectInput from '../../../../components/Forms/Select';
import PeriodicityInput from '../../../../components/Forms/PeriodicityInput';

const BonusRow = ({
  bonuses,
  bonus,
  onChange,
  onSelectChange,
  index,
  onDelete,
  canEdit,
}) => {
  const bonusTypes = [
    { label: 'Fijo', value: 'static' },
    { label: 'Variable', value: 'variable' },
  ];

  const assignments_options = [
    { label: 'Diaria', value: 'daily' },
    { label: 'Mensual', value: 'monthly' },
  ];
  const [bonusName, setAssignmentName] = useState(() =>
    bonuses.find( b => b.label === bonus.name)?.value
  );
  const setAssignment = (el, assign, index) => {
    bonus.name = el.label;
    setAssignmentName(el.value);
    onChange({ target: { name: 'name', value: el.label } }, index);
  };
  return (
    <tr>
      <td>
        {canEdit ? (
          <SelectInput
            key={`${index}-key`}
            label={'Nombre'}
            name={'name'}
            options={bonuses}
            value={bonusName}
            id={`${index}-req_assign`}
            onChange={(el) => setAssignment(el, bonus, index)}
            required={true}
          />
        ) : (
          bonus.name
        )}
      </td>
      <td>
        {canEdit ? (
          <SelectInput
            key={`${index}-type`}
            id={`${index}-bonus`}
            name='assignment_type'
            value={bonus.assignment_type}
            options={bonusTypes}
            onChange={(v, e) => onSelectChange(v, e, index)}
            required
            label='Tipo'
          />
        ) : (
          bonusTypes.find((b) => b.value === bonus.assignment_type)?.label
        )}
      </td>
      <td>
      {canEdit ? (
        <SelectInput
          key={`${index}-type`}
          id={`${index}-bonus`}
          name='bonus_type'
          value={bonus.bonus_type}
          options={assignments_options}
          onChange={(v, e) => onSelectChange(v, e, index)}
          required
          label='Periodo'
        />)  : (
          assignments_options.find((b) => b.value === bonus.bonus_type)?.label
        )}
      </td>
      <td>
        {canEdit ? (
          <Input
            style={ { width: '110px'} }
            key={`${index}-amount`}
            id={`${index}-bonus`}
            name='amount'
            value={bonus.amount}
            onChange={(e) => onChange(e, index)}
            required
            label='Monto'
          />
        ) : (
          bonus.amount
        )}
      </td>
      {canEdit && (
        <td>
          <button
            key={`${index}-delete`}
            className='btn btn-danger'
            onClick={() => onDelete(index)}
            type='button'
          >
            <i className='fe fe-trash' />
          </button>
        </td>
      )}
    </tr>
  );
};

BonusRow.defaultProps = {};

BonusRow.propTypes = {
  bonus: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
};

export default BonusRow;
