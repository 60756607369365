import React from "react";
import PropTypes from "prop-types";

const InputInfo = ({ title, value, type }) => (
  <div className="mb-1" style={{ fontSize: "16px" }}>
    <b className="d-block">{title}</b>
    {type === "textArea" ? (
      <textarea
        rows={4}
        style={{ width: "100%" }}
        disabled={true}
        value={value}
      />
    ) : (
      <span
        className="d-block form-control bg-light"
        style={{ height: "38px" }}
      >
        {value}
      </span>
    )}
  </div>
);

InputInfo.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string
};

InputInfo.defaultProps = {
  value: ""
};

export default InputInfo;
