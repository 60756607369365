import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import Paginator from '../Pagination';
import { useRef } from 'react';
import DataTable from 'react-data-table-component';
import { Button, Col } from 'react-bootstrap';
import axios from 'axios';

const Table = ({
  columns,
  rows,
  actions,
  entityClass,
  pagination,
  editModal,
  isEntity,
  params,
}) => {
  const [mainParams, setMainParams] = useState(params || {});
  const allEntities = useRef(mainParams.all === 'true');
  const [selectedRows, setSelectedRows] = useState([]);

  const [filteredRows, setFilteredRows] = useState(rows);

  const paginationComponentOptions = {
    rowsPerPageText: 'Filas por página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',
  };

  const enDisText = (val) => (val ? 'Deshabilitar' : 'Habilitar');
  const delLinkTitle = (val) => (isEntity ? enDisText(val) : 'Eliminar');

  const handleRowSelected = (rows) => {
    setSelectedRows(rows.selectedRows.map((r) => r.id));
  };

  const handleDestroySelected = (soft = false) => {
    const confirmAction = soft ? 'Deshabilitar' : 'Eliminar';
    if (
      confirm(
        `¿Está seguro(a) de que sedea ${confirmAction} todo lo seleccionado?`
      )
    ) {
      axios
        .get(
          `/${isEntity ? 'maintainers' : entityClass}/destroy_all${
            isEntity ? `?entity_class=${entityClass}` : ''
          }`,
          { params: { ids: selectedRows, soft, entityClass } }
        )
        .then((res) => {
          if (res.status === 200) {
            window.location.reload();
          }
        })
        .catch((e) => {
          console.log(e);
          alert('Ocurrió un error al procesar solicitud');
        });
    }
  };

  const handleSearch = (value) => {
    const filteredItems = rows.filter((item) => {
      const rowValues = Object.values(item)
        .map((el) => {
          if (typeof el === 'object') {
            return el?.name?.toLowerCase();
          }
          return el;
        })
        .join('')
        .toLowerCase();

      return rowValues.includes(value.toLowerCase());
    });
    setFilteredRows(filteredItems);
  };

  const subHeaderComponent = (
    <div className='row mr-auto w-100'>
      <Col md={3} lg={3} xl={3}>
        <input
          type='text'
          className='form-control'
          placeholder='Buscar'
          onChange={(e) => handleSearch(e.target.value)} // Add your search logic here
        />
      </Col>
      {isEntity && (
        <>
          <Col md={2} lg={2} xl={2}>
            <Button
              variant='warning'
              onClick={() => handleDestroySelected(true)}
              disabled={selectedRows.length <= 0}
            >
              Deshabilitar Sel.
            </Button>
          </Col>
          <Col md={2} lg={2} xl={2}>
            <Button
              variant='danger'
              onClick={() => handleDestroySelected()}
              disabled={selectedRows.length <= 0}
            >
              Eliminar Sel.
            </Button>
          </Col>
        </>
      )}
    </div>
  );

  const dtColumns = useMemo(() => [
    ...columns.map((column) => ({
      //id: column.attribute_name,
      name: column.label,
      //key: column.attribute_name,
      selector: (row) => column.value(row),
      sortable: true,
    })),
    {
      name: 'Acciones',
      cell: (row) => (
        <>
          <a
            href={`/${entityClass}/${row.id}/edit`}
            className='btn btn-sm btn-success mr-1'
            data-bs-toggle={editModal ? 'modal' : 'tooltip'}
            data-bs-placement='top'
            data-bs-target={`#${entityClass}-${row.id}`}
            title='Editar'
            key={`${row.id}-edit`}
          >
            <i className='fe fe-edit'></i>
          </a>
          <a
            href={`/${isEntity ? 'maintainers' : entityClass}/${row.id}${
              isEntity ? `?entity_class=${entityClass}` : ''
            }`}
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            className={isEntity ? 'btn mb-4' : 'btn btn-sm btn-danger mr-1'}
            title={delLinkTitle(row.enabled)}
            rel='nofollow'
            data-method='delete'
            data-confirm={`¿Estás seguro que quieres ${delLinkTitle(
              row.enabled
            )}?`}
            key={`${row.id}-del`}
          >
            {isEntity ? (
              <div className='form-switch'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  role='switch'
                  id='flexSwitchCheckChecked'
                  checked={row.enabled}
                />
              </div>
            ) : (
              <i className='fe fe-trash' />
            )}
          </a>
        </>
      ),
      allowOverflow: true,
      button: true,
    },
  ], [columns]);

  const handleGetParameter = (type, value) => {
    const searcher = new URLSearchParams(window.location.search.substr(1));
    searcher.set(type, value);
    window.location.search = searcher.toString();
  };

  const handleCheckboxChange = (event) => {
    const {
      target: { name, checked },
    } = event;
    const searcher = new URLSearchParams(window.location.search.substr(1));
    searcher.set(name, checked);
    window.location.search = searcher.toString();
  };

  return (
    <>
      {isEntity && (
        <div className='d-flex justify-content-end mt-3 mb-1 px-5'>
          <div className='form-check form-switch'>
            <label htmlFor='switch_all' className='form-check-label mr-5 px-5'>
              Ver todo
            </label>
            <input
              className={`form-check-input ${allEntities.current &&
                'bg-green'}`}
              type='checkbox'
              name='all'
              checked={allEntities.current}
              onChange={handleCheckboxChange}
              id='switch_all'
            />
          </div>
        </div>
      )}
      <DataTable
        columns={dtColumns}
        data={filteredRows}
        pagination
        selectableRows
        onSelectedRowsChange={handleRowSelected}
        subHeader
        subHeaderComponent={subHeaderComponent}
        paginationComponentOptions={paginationComponentOptions}
      />
    </>
  );
  // return (
  //   <>
  //     {isEntity && (
  //       <div className="d-flex justify-content-end mt-3 mb-1 px-5">
  //         <div className="form-check form-switch">
  //           <label htmlFor="switch_all" className='form-check-label mr-5 px-5'>Ver todo</label>
  //           <input
  //             className={`form-check-input ${allEntities.current && "bg-green"}`}
  //             type="checkbox"
  //             name="all"
  //             checked={allEntities.current}
  //             onChange={handleCheckboxChange}
  //             id="switch_all"
  //           />
  //         </div>
  //       </div>
  //     )}
  //     <div className="table-responsive">
  //       <table className="table table-hover table-outline table-vcenter card-table">
  //         <thead>
  //           <tr>
  //             {columns.map((column) => (
  //               <th key={column.key}>
  //                 {column.label}
  //                 <span
  //                   onClick={() =>
  //                     handleGetParameter("order", `${column.key || "id"} asc`)
  //                   }
  //                 >
  //                   <i className="fe fe-chevron-up" />
  //                 </span>
  //                 <span
  //                   onClick={() =>
  //                     handleGetParameter("order", `${column.key || "id"} desc`)
  //                   }
  //                 >
  //                   <i className="fe fe-chevron-down" />
  //                 </span>
  //               </th>
  //             ))}
  //             {actions.length > 0 && <th>Acciones</th>}
  //           </tr>
  //         </thead>
  //         <tbody>

  //           {rows.map((row, idx) => (
  //             <tr key={idx}>
  //               {columns.map((column) => (
  //                 <td key={`${column.key || column.label}-${column.value(row) || column.value}`}>
  //                   {column.value(row) || column.options?.find(e => e.value == row[column.attribute_name])?.label}
  //                 </td>
  //               ))}
  //               <td key={`${idx}-tr`}>
  //                 {actions.includes("edit") && (
  //                   <a
  //                     href={`/${entityClass}/${row.id}/edit`}
  //                     className="btn btn-sm btn-success mr-1"
  //                     data-bs-toggle={editModal ? "modal" : "tooltip"}
  //                     data-bs-placement="top"
  //                     data-bs-target={`#${entityClass}-${row.id}`}
  //                     title="Editar"
  //                     key={`${idx}-edit`}
  //                   >
  //                     <i className="fe fe-edit"></i>
  //                   </a>
  //                 )}
  //                 {actions.includes("delete") && (
  //                   <a
  //                     href={`/${isEntity ? "maintainers" : entityClass}/${row.id
  //                       }${isEntity ? `?entity_class=${entityClass}` : ""}`}
  //                     data-bs-toggle="tooltip"
  //                     data-bs-placement="top"
  //                     className={isEntity ? 'btn mb-4' : 'btn btn-sm btn-danger mr-1'}
  //                     title={delLinkTitle(row.enabled)}
  //                     rel="nofollow"
  //                     data-method="delete"
  //                     data-confirm={`¿Estás seguro que quieres ${delLinkTitle(row.enabled)}?`}
  //                     key={`${idx}-del`}
  //                   >
  //                     {
  //                       isEntity ? (
  //                         <div className="form-switch">
  //                           <input className="form-check-input"
  //                             type="checkbox"
  //                             role="switch"
  //                             id="flexSwitchCheckChecked"
  //                             checked={row.enabled} />
  //                         </div>
  //                       ) : (
  //                         <i className="fe fe-trash" />
  //                       )
  //                     }
  //                   </a>
  //                 )}
  //               </td>
  //             </tr>
  //           ))}
  //         </tbody>
  //       </table>
  //       {pagination && (
  //         <Paginator
  //           pagination={pagination}
  //           setGetParameter={handleGetParameter}
  //         />
  //       )}
  //     </div>
  //   </>
  // );
};

Table.defaultProps = {
  columns: [],
  rows: [],
  actions: [],
  editModal: false,
  isEntity: false,
};

Table.propTypes = {
  columns: PropTypes.array,
  rows: PropTypes.array,
  actions: PropTypes.array,
  entityClass: PropTypes.string.isRequired,
  editModal: PropTypes.bool,
  isEntity: PropTypes.bool,
};

export default Table;
