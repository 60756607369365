import React, { useCallback } from "react";
import PropTypes from "prop-types";

const CheckboxGroup = ({
  title,
  options,
  onChange,
  name,
  value,
  isMulti,
  disabled,
  required
}) => {
  const isChecked = useCallback(
    optionValue => {
      if (!isMulti) {
        return value == optionValue;
      }
      return value?.find(val => val == optionValue) || false;
    },
    [value]
  );

  return (
    <div className="row g-2">
      {title && (
        <div className="col-12 font-weight-bold">
          {required ? "(*)" : ""} {title}
        </div>
      )}
      {required && (
        <input
          tabIndex={-1}
          autoComplete="off"
          style={{ opacity: 0, height: 0, margin: 0, padding: 0 }}
          value={value || ""}
          required={required}
          onChange={() => {}}
          disabled={disabled}
        />
      )}
      {options.map(opt => (
        <div className="form-check col-12 ml-3" key={opt.value}>
          <input
            className="form-check-input"
            type="checkbox"
            id={`checkbox-${name}-${opt.value}`}
            onChange={(e, o) => onChange(e, o, title, { label: name, value: name })}
            name={name}
            value={opt.value}
            checked={isChecked(opt.value)}
            disabled={disabled}
          />
          <label
            className="form-check-label"
            htmlFor={`checkbox-${name}-${opt.value}`}
          >
            {opt.label}
          </label>
        </div>
      ))}
    </div>
  );
};

CheckboxGroup.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array,
  title: PropTypes.string,
  isMulti: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array
  ]),
  required: PropTypes.bool,
  disabled: PropTypes.bool
};

CheckboxGroup.defaultProps = {
  title: "",
  options: [],
  isMulti: false,
  value: "",
  required: false,
  disabled: false
};

export default CheckboxGroup;
