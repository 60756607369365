import React, { useState } from "react";
import PropTypes from "prop-types";
import Input from "../../components/Forms/Input";
import CheckboxGroup from "../../components/Forms/CheckboxGroup";
import Errors from "../../components/Layouts/Errors";
import {
  updateBranchOffice,
  createBranchOffice
} from "../../services/branchOffices";

const Form = ({ branchOffice, modalId, csrf, companies }) => {
  const [form, setForm] = useState(branchOffice);
  const [errors, setErrors] = useState([]);

  const handleSubmitSuccess = () => {
    const { id } = form;
    alert(`Sucursal ${id ? "actualizada" : "creada"} con éxito!`);
    window.location.reload();
  };

  const createOrUpdateBranchOffice = () => {
    const { id } = form;
    return id
      ? updateBranchOffice(id, form, csrf)
      : createBranchOffice(form, csrf);
  };

  const handleSubmit = e => {
    e.preventDefault();
    createOrUpdateBranchOffice(form, csrf).then(
      () => handleSubmitSuccess(),
      err => setErrors(err.response.data)
    );
  };

  const handleInputChange = event => {
    const {
      target: { name, value }
    } = event;
    setForm({ ...form, [name]: value });
  };

  const handleCheckboxChange = event => {
    const {
      target: { value, name }
    } = event;
    setForm({ ...form, [name]: value });
  };

  return (
    <div
      className="modal fade"
      id={modalId}
      tabIndex="-1"
      aria-labelledby={`${modalId}Label`}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id={`${modalId}Label`}>
              Nueva Sucursal
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body">
            <form className="row" onSubmit={handleSubmit}>
              <div className="col-12">
                <Errors errors={errors} />
              </div>
              <div className="col-xs-12 col-md-9 col-lg-8">
                <div className="row g-2">
                  <div className="col-xs-12 col-md-6">
                    <Input
                      onChange={handleInputChange}
                      value={form.name}
                      label="Nombre"
                      name="name"
                      id={`${modalId}-branchOfficeName`}
                      required
                    />
                  </div>
                  <div className="col-xs-12 col-md-6">
                    <Input
                      onChange={handleInputChange}
                      value={form.code}
                      label="Código"
                      name="code"
                      id={`${modalId}-branchOfficeCode`}
                      required
                    />
                  </div>
                  <div className="col-12">
                    <CheckboxGroup
                      value={form.company_id}
                      options={companies}
                      name="company_id"
                      title="Pertenece a Empresa:"
                      onChange={handleCheckboxChange}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-md-3 col-lg-4 d-flex align-items-center">
                <div className="btn-group-vertical w-100">
                  <button type="submit" className="btn btn-outline-success">
                    Guardar
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-warning mt-2"
                    data-bs-dismiss="modal"
                  >
                    Cancelar
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

Form.propTypes = {
  modalId: PropTypes.string.isRequired,
  csrf: PropTypes.string.isRequired,
  branch_office: PropTypes.object,
  companies: PropTypes.array
};

Form.defaultProps = {
  companies: [],
  branch_office: {}
};

export default Form;
