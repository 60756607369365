import React, { useState } from "react";
import { toast } from "react-toastify";
import { useReactToPrint } from "react-to-print";
import { updateStatus } from "../../../services/requests";
import {
  STATUS_ACCEPTED_BY_VALIDATOR,
  STATUS_REJECTED_BY_VALIDATOR
} from "./constants";
import Input from "../../../components/Forms/Input";

const GeneralistAccepForm = ({ form, user, csrf, formRef }) => {
  const { id } = form;
  const [loading, setLoading] = useState({
    accept: false,
    reject: false
  });
  const handleOnPrint = useReactToPrint({
    content: () => formRef.current,
    copyStyles: true,
    documentTitle: "Solicitud de inclusión"
  });

  const [positionNumber, setPositionNumber] = useState(
    form.position_number || ""
  );

  const handleInputChange = event => {
    const {
      target: { value }
    } = event;
    setPositionNumber(value);
  };

  const handleSubmit = (params, name) => {
    setLoading({ ...loading, [name]: true });
    const formData = new FormData();
    formData.append("request[position_number]", positionNumber);
    Object.keys(params).forEach(key => {
      formData.append(`request[${key}]`, params[key]);
    });
    toast.promise(
      updateStatus(id, formData, csrf),
      {
        pending: "Cargando...",
        success: {
          render({ data }) {
            setTimeout(() => (window.location = "/requests"), 2000);
            const message =
              data.data.status === STATUS_REJECTED_BY_VALIDATOR
                ? "rechazado"
                : "validado";
            return `Se ha ${message} la solicitud de inclusión`;
          }
        },
        error: "Ocurrió un error realizando la acción."
      },
      {
        autoClose: 2000,
        onClose: () => setLoading({ ...loading, [name]: false }),
        pauseOnHover: false
      }
    );
  };

  const handleOnAccept = e => {
    e.preventDefault();
    if(positionNumber === "") {
      alert("Debe ingresar un número de posición");
      return;
    }
    if(confirm("¿Seguro que quieres aceptar la solicitud?")) {
      handleSubmit(
        {
          validator_id: user.id,
          validation_date: new Date(),
          status: STATUS_ACCEPTED_BY_VALIDATOR
        },
        "accept"
      );
    }
  };

  const handleOnReject = e => {
    e.preventDefault();
    if (confirm("¿Seguro que quieres rechazar la solicitud?")) {
      handleSubmit({ status: STATUS_REJECTED_BY_VALIDATOR }, "reject");
    }
  };

  return (
    <div className="card">
      <div className="card-body row g-2">
        <div className="d-flex flex-row">
          <div className={"flex-grow-1"}>
            <Input
              type="number"
              id="position_number"
              required={true}
              name="position_number"
              className="form-control"
              value={positionNumber || ""}
              onChange={handleInputChange}
              label="N° de Posición"
            />
          </div>
          <button
            type="button"
            className="btn btn-outline-primary"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-bs-target="print"
            title="Imprimir"
            onClick={handleOnPrint}
          >
            <i className="fe fe-printer" />
          </button>
        </div>
        <div className="col-12 mt-5 g-1 p-0 d-flex justify-content-xs-between justify-content-around">
          <button
            type="submit"
            className="btn btn-outline-success"
            onClick={handleOnAccept}
            disabled={loading.accept}
          >
            {loading.accept && (
              <span
                className="spinner-border spinner-border-sm mr-1"
                role="status"
                aria-hidden="true"
              />
            )}
            <i className="fe fe-check" />
            Aceptar
          </button>
          <button
            type="submit"
            className="btn btn-outline-danger"
            onClick={handleOnReject}
            disabled={loading.reject}
          >
            {loading.reject && (
              <span
                className="spinner-border spinner-border-sm mr-1"
                role="status"
                aria-hidden="true"
              />
            )}
            <i className="fe fe-x" />
            Rechazar
          </button>
        </div>
      </div>
    </div>
  );
};

export default GeneralistAccepForm;
