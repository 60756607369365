import React, { useCallback, useEffect, useState } from "react";
import Consumer from "../../../channels/consumer";
import { DateFormat } from "../../../components/Utils";
import { STATUS_ACCEPTED_BY_APPLICANT } from "../Form/constants";
import RequestStatusBadge from "./RequestStatusBadge";

const RequestRow = ({ request, user }) => {
  const [hasNewNotifications, setHasNewNotifications] = useState(
    request.has_non_viewed_notifications
  );
  const daysBetween = useCallback(() => {
    if (!request.creation_date) return "";
    return Math.floor(
      (new Date(request.creation_date) - new Date(request.application_date)) /
        (24 * 60 * 60 * 1000)
    );
  }, [request]);

  const isFinished = useCallback(() => {
    return request.status === STATUS_ACCEPTED_BY_APPLICANT;
  }, [request.status]);

  useEffect(() => {
    const actualConsumer = Consumer.subscriptions.subscriptions.find(
      s => JSON.parse(s.identifier).request_id == request.id
    );
    Consumer.subscriptions.create(
      {
        channel: "NotificationsChannel",
        request_id: request.id
      },
      {
        received: ({ viewed }) => setHasNewNotifications(viewed)
      }
    );
    return actualConsumer?.unsubscribe();
  }, []);

  const canDelete = (role, status) => {
    const admins = ["administrator", "superadministrator"]
    if(admins.includes(role)) return true;
    const roles = ["applicant"]
    const statuses = ["waiting_for_validation", "draft"]
    return statuses.includes(status) && roles.includes(role)
  }

  return (
    <tr>
      <td>
        <a href={`/requests/${request.id}/edit`}>{request.id}</a>
      </td>
      <td>{DateFormat(request.application_date)}</td>
      <td>{request.applicant?.name}</td>
      <td>{request.company?.name}</td>
      <td>{request.branch_office?.name}</td>
      <td>
        {request.candidate_name} {request.candidate_lastname}{" "}
        {request.candidate_second_lastname}
      </td>
      <td>{request.position?.name}</td>
      <td align="center">
        <i className="fe fe-message-square position-relative">
          {hasNewNotifications && (
            <span className="position-absolute top-0 start-100 translate-middle p-1 bg-danger border border-light rounded-circle">
              <span className="visually-hidden"></span>
            </span>
          )}
        </i>
      </td>
      <td>
        <RequestStatusBadge status={request.status_name} />
      </td>
      <td>{request.validator?.name}</td>
      <td>{DateFormat(request.validation_date)}</td>
      <td>{request.person_number}</td>
      <td>
        {request.contract_url && (
          <a
            className="d-flex justify-content-center"
            href={request.contract_url}
            role="button"
            target="_blank"
          >
            <i className="fe fe-file-text" />
          </a>
        )}
      </td>
      <td>{request.creator?.name}</td>
      <td>{DateFormat(request.creation_date)}</td>
      <td align="center">{daysBetween()}</td>
      <td>
        {isFinished() && (
          <h3 className="m-0">
            <i className="fe fe-check font-weight-bold text-green display-8" />
          </h3>
        )}
      </td>
      <td>
        {
          canDelete(user.role, request.status, ) && (
            <a href={`/requests/${request.id}`} 
              className='font-weight-bold fs-4'
              data-method="delete"
              data-confirm="¿Estás seguro(a) de eliminiar?">🗑️</a>
          )
        }
      </td>
    </tr>
  );
};

export default RequestRow;
