import React, { useMemo } from "react";
import Pagination from "../../../../components/Pagination";
import { DateFormat } from "../../../../components/Utils";
import { daysCounterByType } from "./Utils";

const Table = ({ pagination, rows, type, handlePageChange }) => {
  const columns = useMemo(
    () => [
      { label: "ID", value: f => f.id },
      { label: "Empresa", value: f => f.company?.name },
      { label: "Sucursal", value: f => f.branch_office?.name },
      {
        label: "Fecha Solicitud",
        value: f => DateFormat(f.application_date).replace(/[/]/g, ".")
      },
      { label: "Contador días", value: f => daysCounterByType(f, type) }
    ],
    [type]
  );

  return (
    <div className="card p-1">
      <div className="table-responsive">
        <table className="table table-hover table-outline table-vcenter">
          <thead>
            <tr align="center">
              {columns.map(column => (
                <th key={column.label}>{column.label}</th>
              ))}
              <th>Ver ficha</th>
            </tr>
          </thead>
          <tbody>
            {rows.map(row => (
              <tr key={row.id} align="center">
                {columns.map(column => (
                  <td key={`${column.label}-${row.id}`}>{column.value(row)}</td>
                ))}
                <td>
                  <a href={`/requests/${row.id}/edit`}>
                    <i className="fe fe-paperclip" />
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <Pagination
          pagination={pagination}
          setGetParameter={handlePageChange}
        />
      </div>
    </div>
  )
};

export default Table;