import React, { useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import { ToastContainer, toast } from "react-toastify";
import Errors from "../../../components/Layouts/Errors";
import Toast from "../../../components/Layouts/Toast";
import { createRequest, getFormPdf, updateRequest, deleteRequest } from "../../../services/requests";
import MainForm from "./forms/MainForm";
import AcademicForm from "./forms/AcademicForm";
import ForecastDataForm from "./forms/ForecastDataForm";
import ContractForm from "./forms/ContractForm";
import OrganizationalForm from "./forms/OrganizationalForm";
import RemunerationsForm from "./forms/RemunerationsForm";
import BankForm from "./forms/BankForm";
import CostDistribution from "./costDistribution";
import FilesForm from "./forms/FilesForm";
import MasterDataForm from "./MasterDataForm";
import GeneralistAcceptForm from "./GeneralistAcceptForm";
import ApplicantForm from "./ApplicantForm";
import Comments from "./comments";
import {
  STATUS_ACCEPTED_BY_APPLICANT,
  STATUS_ACCEPTED_BY_MASTER_DATA,
  STATUS_ACCEPTED_BY_VALIDATOR,
  STATUS_REJECTED_BY_APPLICANT,
  STATUS_REJECTED_BY_VALIDATOR,
  STATUS_WAITING_FOR_VALIDATION
} from "./constants";
import {
  AddCostsToFormData,
  AddFilesToFormData,
  AddSchedulePlansToFormData,
  AddBonusesToFormData,
  AddAssignmentsToFormData,
  AddReferencesToFormData,
} from "./Utils";
import { reference } from '@popperjs/core';

const Form = ({
  references,
  assignments,
  relationships,
  request_assignments,
  person_acceptation,
  request,
  user,
  bonuses,
  request_bonuses,
  cost_distributions, 
  business_lines,
  schedule_plans,
  cost_centers,
  chore_addresses,
  request_files,
  files,
  regions,
  communes,
  extreme_zones,
  educational_levels,
  countries,
  qualified_levels,
  afps,
  health_institutions,
  contract_types,
  contract_classes,
  attendance_marks,
  time_types,
  personnel_divisions,
  staff_areas,
  positions,
  sub_staff_areas,
  divisions,
  payroll_areas,
  personal_sub_groups,
  banks,
  bank_controls,
  payment_methods,
  nacionalities,
  companies,
  branch_offices,
  csrf,
  apvs,
  specialties,
  afp_concepts,
  apv_concepts,
  health_concepts,
  organizational_units
}) => {
  const { id } = request;
  const { status } = request;
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [requestReferences, setRequestReferences] = useState(references);
  const [form, setForm] = useState(()=>{
    references.forEach(ref => {
      request[ref.ref_name] = ref.value;
    });
    request.message = person_acceptation?.message;
    return request;
  });
  const [costs, setCosts] = useState(cost_distributions);
  const [requestBonuses, setRequestBonuses] = useState(request_bonuses);
  const [requestAssignments, setRequestAssignments] = useState(request_assignments);
  const [schedulePlans, setSchedulePlans] = useState(schedule_plans);
  const [requestFiles, setRequestFiles] = useState(request_files);
  const [errors, setErrors] = useState([]);
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [prevChanges, setPrevChanges] = useState({});
  const formRef = useRef();
  const handleInputChange = event => {
    const {
      target: { name, value }
    } = event;
    if(event.target.type === "checkbox") {
      const checkValue = event.target.nextElementSibling.textContent;
      const checkLabel = event.target.parentElement.parentElement.firstChild.textContent;
      setPrevChanges({ ...prevChanges, [checkLabel]: checkValue });
    } else {
      const label = event.target.getAttribute("placeholder").split("-")[0];
      setPrevChanges({ ...prevChanges, [label]: value });
    }
    setForm({ ...form, [name]: value });
  };

  const handleRutChange = ({ value }, { name }) => {
    setForm({ ...form, [name]: value });
  };

  const handleSelectChange = (event, input, label, selectedValue) => {
    const name = input?.name || event.target?.name;
    const value = event?.value || event.target?.value;
    if (name.includes("contract_type_id")) {
      const checkLabel =
        event.target.parentElement.parentElement.firstChild.textContent;
      const checkValue = event.target.nextElementSibling.textContent;
      setPrevChanges({ ...prevChanges, [checkLabel]: checkValue });
    } else {
      setPrevChanges({ ...prevChanges, [label]: selectedValue.label });
    }
    // Add the selected value to form that control selectors
    setForm({ ...form, [name]: value });
    if (name.endsWith("_id")) {
      const newRequestReferences = requestReferences.slice();
      let exist = false;
      const entity = name.split("_id")[0];
      const maintainerable_id = value;
      let maintainerable_type = "";
      // make an undercore word to class string
      entity.split("_").forEach(e => {
        maintainerable_type += e[0].toUpperCase() + e.slice(1);
      });

      newRequestReferences.forEach(reqRef => {
        if (reqRef.name === name) {
          reqRef.maintainerable_id = value || "";
          exist = true;
        }
      });
      if(!exist) newRequestReferences.push({maintainerable_type, maintainerable_id, name});

      setRequestReferences(newRequestReferences);
    }
  };

  const handleAddCost = () => {
    const newCosts = [
      ...costs,
      { name: "", internal_order: "", percentage: "" }
    ];
    setCosts(newCosts);
  };

  const handleDeleteCost = index => {
    if (costs.filter((e) => !(e._destroy === true)).length > 1) {
      const newCosts = costs.slice();
      newCosts[index]._destroy = true;
      setCosts(newCosts);
    }
  };

  const handleCostChange = (event, index) => {
    const {
      target: { name, value }
    } = event;
    const newCosts = costs.slice();
    newCosts[index][name] = value;
    const dict = {
      name: "Nombre",
      code: "Nombre",
      internal_order: "Orden Interna",
      percentage: "Porcentaje"
    };
    setCosts(newCosts);
    setPrevChanges({
      ...prevChanges,
      [`Centro de Costo ${index + 1} ${dict[name]}`]: value
    });
  };

  const handleAddSchedulePlan = () => {
    const newSchedulePlan = [
      ...schedulePlans,
      { name: "" }
    ];
    setSchedulePlans(newSchedulePlan);
  };

  const handleDeleteSchedulePlan = index => {
    if (schedulePlans.filter((e) => !(e._destroy === true)).length > 1) {
      const newSchedulePlans = schedulePlans.slice();
      newSchedulePlans[index]._destroy = true;
      setSchedulePlans(newSchedulePlans);
    }
  };

  const handleSchedulePlanChange = (event, index) => {
    const {
      target: { name, value }
    } = event;
    const dict = { name: "Nombre" };
    const newSchedulePlans = schedulePlans.slice();
    newSchedulePlans[index][name] = value;
    setSchedulePlans(newSchedulePlans);
    setPrevChanges({
      ...prevChanges,
      [`Plan Horario ${index + 1} ${dict[name]}`]: value
    });
  };

  const handleAddBonus = () => {
    const newBonuses = [
      ...requestBonuses,
      { name: "", assignment_type: "", amount: "", bonus_type: "" }
    ];
    setRequestBonuses(newBonuses);
  };

  const handleDeleteBonus = index => {
    const newBonuses = requestBonuses.slice();
    newBonuses[index]._destroy = true;
    setRequestBonuses(newBonuses);
  };

  const handleBonusChange = (event, index) => {
    const {
      target: { name, value }
    } = event;
    const newBonuses = requestBonuses.slice();
    newBonuses[index][name] = value;
    setRequestBonuses(newBonuses);
    const dict = {
      name: "Nombre",
      code: "Nombre",
      assignment_type: "Tipo Asignación",
      amount: "Monto",
      bonus_type: "Tipo Bono"
    };
    setPrevChanges({
      ...prevChanges,
      [`Bonos ${index + 1} ${dict[name]}`]: value
    });
  };

  const handleBonusSelectChange = ({ value }, { name }, index) => {
    const newBonuses = requestBonuses.slice();
    newBonuses[index][name] = value;
    setRequestBonuses(newBonuses);

    const dict = {
      name: "Nombre",
      code: "Nombre",
      assignment_type: "Tipo Asignación",
      amount: "Monto",
      bonus_type: "Tipo Bono"
    };
    setPrevChanges({
      ...prevChanges,
      [`Bonos ${index + 1} ${dict[name]}`]: value
    });
  };

  const handleAddAssignment = () => {
    const newAssignments = [
      ...requestAssignments,
      { name: "", assignment_type: "", amount: "" }
    ];
    setRequestAssignments(newAssignments);
  };

  const handleDeleteAssignment = index => {
    const newAssignments = requestAssignments.slice();
    newAssignments[index]._destroy = true;
    setRequestAssignments(newAssignments);
  };

  const handleAssignmentChange = (event, index) => {
    const {
      target: { name, value }
    } = event;
    const newAssignments = requestAssignments.slice();
    newAssignments[index][name] = value;
    setRequestAssignments(newAssignments);

    const dict = {
      name: "Nombre",
      code: "Nombre",
      periodicity: "Periodo",
      assignment_type: "Tipo Asignación",
      amount: "Monto"
    };
    setPrevChanges({
      ...prevChanges,
      [`Asignaciones ${index + 1} ${dict[name]}`]: value
    });
  };

  const handleAssignmentSelectChange = ({ value }, { name }, index) => {
    const newAssignments = requestAssignments.slice();
    newAssignments[index][name] = value;
    setRequestAssignments(newAssignments);
    const dict = {
      name: "Nombre",
      code: "Nombre",
      periodicity: "Periodo",
      assignment_type: "Tipo Asignación",
      amount: "Monto"
    };
    setPrevChanges({
      ...prevChanges,
      [`Asignaciones ${index + 1} ${dict[name]}`]: value
    });
  };

  const handleFileChange = (event, fileId) => {
    const {
      target: {
        files: [document]
      }
    } = event;
    const newRequestFiles = requestFiles.slice();
    let requestFileIndex = newRequestFiles.findIndex(
      r => r.file_maintainer_id == fileId
    );
    if (requestFileIndex < 0) {
      newRequestFiles.push({ file_maintainer_id: fileId, document });
      requestFileIndex = 0;
    } else {
      newRequestFiles[requestFileIndex].document = document;
      newRequestFiles[requestFileIndex].file_maintainer_id = fileId;
    }
    setRequestFiles(newRequestFiles);
  };

  const handleFileEdit = (fileId, editing = true) => {
    const newFiles = requestFiles.slice();
    const fileIndex = newFiles.findIndex(f => f.file_maintainer_id == fileId);
    if (fileIndex >= 0) newFiles[fileIndex].editing = editing;
    setRequestFiles(newFiles);
  };

  const handleFileDestroy = fileId => {
    const newFiles = requestFiles.slice();
    const fileIndex = newFiles.findIndex(f => f.file_maintainer_id == fileId);
    if (fileIndex >= 0) newFiles.splice(fileIndex, 1);
    setRequestFiles(newFiles);
  };

  const createOrUpdateRequest = data => {
    return id ? updateRequest(id, data, csrf) : createRequest(data, csrf);
  };

  const handleSubmitSuccess = req => {
    const message = id
      ? `Solicitud actualizada con éxito`
      : `Solicitud creada con éxito! En espera de validación.`;
    toast.success(<Toast title={`#${req.id}`} message={message} />, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      draggable: true,
      progress: undefined,
      onClose: () => {
        window.location = "/requests";
      }
    });
  };

  const createFormData = (draft = false) => {
    const formData = new FormData();
    if (draft) {
      formData.append("request[status]", "draft");
    }
    else if (!draft || status === STATUS_REJECTED_BY_VALIDATOR) {
      formData.append("request[status]", STATUS_WAITING_FOR_VALIDATION);
      form.status = STATUS_WAITING_FOR_VALIDATION
      formData.append("request[application_date]", new Date());
    }
    Object.keys(form).forEach(key => {
      if (key.endsWith("_id") && !['validator_id', 'applicant_id', 'creator_id'].includes(key)) {}
      else if (form[key]) formData.append(`request[${key}]`, form[key]);
    });

    AddCostsToFormData(formData, costs);
    AddSchedulePlansToFormData(formData, schedulePlans);
    AddFilesToFormData(formData, requestFiles);
    AddReferencesToFormData(formData, requestReferences);
    if (requestBonuses.length > 0)
      AddBonusesToFormData(formData, requestBonuses);
    if (requestAssignments.length > 0)
      AddAssignmentsToFormData(formData, requestAssignments);

    return formData;
  };

  const handleSubmit = event => {
    setIsSubmitted(true);
    event.preventDefault();
    const {
      nativeEvent: {
        submitter: { formNoValidate: draft }
      }
    } = event;
    const formData = createFormData(draft);
    if (confirm(`Los siguientes cambios serán guardados: \n${JSON.stringify(prevChanges, null, 2)}\n¿Desea continuar?`)) {
      createOrUpdateRequest(formData)
        .then(
          response => handleSubmitSuccess(response.data),
          err => {
            setErrors(err.response.data);
            setForm({ ...form, status: "draft" });
          }
        )
        .finally(() => {
          const submitButtons = document.querySelectorAll('[type="submit"]');
          // Loop through all submit buttons and remove 'data-disable-with' attribute
          submitButtons.forEach(button => {
            button.removeAttribute("data-disable-with");
          });
          setIsSubmitted(false)
        });
    } else {
      setIsSubmitted(false);
      const submitButtons = document.querySelectorAll('[type="submit"]');
      // Loop through all submit buttons and remove 'data-disable-with' attribute
      submitButtons.forEach(button => {
        button.removeAttribute("data-disable-with");
      });
    }
  };

  const handleDownload = () => {
    setLoadingPdf(true);
    getFormPdf(id)
      .then(
        ({ data }) => {
          const binaryString = atob(data);
          const len = binaryString.length;
          const buffer = new ArrayBuffer(len);
          const view = new Uint8Array(buffer);
          for (let i = 0; i < len; i++) {
            view[i] = binaryString.charCodeAt(i);
          }
          const blob = new Blob([view], { type: "application/pdf" });
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `test.pdf`);
          document.body.appendChild(link);
          link.click();
        },
        () => toast.error("Hubo un error al cargar el PDF.")
      )
      .finally(() => setLoadingPdf(false));
  };

  const isUserRole = roles => {
    const { role } = user;
    return roles.includes(role);
  };

  const isAdmin = () => {
    const { role } = user;
    return role === "administrator" || role === "superadministrator";
  };

  const requestCompany = useMemo(() => {
    if (!form.company_id) return null;
    return companies.find(c => c.value == form.company_id);
  }, [form.company_id]);

  const shouldRenderSideForm = useMemo(() => {
    if (
      isUserRole(["administrator"]) || isUserRole(["superadministrator"])
    )
      return true;
    if (
      isUserRole(["applicant"]) &&
      [STATUS_ACCEPTED_BY_MASTER_DATA, STATUS_ACCEPTED_BY_APPLICANT].includes(request.status)
    )
      return true;
    if (
      isUserRole(["generalist"]) &&
      [STATUS_WAITING_FOR_VALIDATION, STATUS_REJECTED_BY_VALIDATOR].includes(
        request.status
      )
    )
      return true;
    if (
      isUserRole(["master_data"]) &&
      [STATUS_ACCEPTED_BY_VALIDATOR, STATUS_REJECTED_BY_APPLICANT].includes(
        request.status
      )
    )
      return true;
    return false;
  }, [request.id]);

  const canEdit = useMemo(() => {
    if (user.role !== "applicant") return false;
    return ![
      STATUS_WAITING_FOR_VALIDATION,
      STATUS_ACCEPTED_BY_VALIDATOR,
      STATUS_ACCEPTED_BY_APPLICANT,
      STATUS_ACCEPTED_BY_MASTER_DATA
    ].includes(form.status);
  }, []);

  return (
    <div className="row g-3">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        rtl={false}
        closeOnClick
        newestOnTop
        pauseOnFocusLoss
        draggable
      />
      <form
        onSubmit={handleSubmit}
        className={form.id ? "col-md-8 col-xs-12" : "col-12"}
      >
        <Errors errors={errors} />
        <section ref={formRef} className="print-container">
          <MainForm
            key="org_form"
            form={form}
            onInputChange={handleInputChange}
            onSelectChange={handleSelectChange}
            regions={regions}
            communes={communes}
            extremeZones={extreme_zones}
            nacionalities={nacionalities}
            companies={companies}
            branchOffices={branch_offices}
            relationships={relationships}
            canEdit={canEdit}
          />
          <AcademicForm
            form={form}
            onInputChange={handleInputChange}
            onSelectChange={handleSelectChange}
            educationalLevels={educational_levels}
            countries={countries}
            qualifiedLevels={qualified_levels}
            specialties={specialties}
            canEdit={canEdit}
          />
          <ForecastDataForm
            form={form}
            onSelectChange={handleSelectChange}
            onInputChange={handleInputChange}
            afps={afps}
            afpConcepts={afp_concepts}
            healthInstitutions={health_institutions}
            healthConcepts={health_concepts}
            apvs={apvs}
            apvConcepts={apv_concepts}
            canEdit={canEdit}
          />
          <ContractForm
            form={form}
            onInputChange={handleInputChange}
            onSelectChange={handleSelectChange}
            onRutChange={handleSelectChange}
            contractTypes={contract_types}
            contractClasses={contract_classes}
            choreAddresses={chore_addresses}
            attendanceMarks={attendance_marks}
            timeTypes={time_types}
            schedulePlans={schedulePlans}
            onAddSchedulePlan={handleAddSchedulePlan}
            onDeleteSchedulePlan={handleDeleteSchedulePlan}
            onInputSchedulePlanChange={handleSchedulePlanChange}
            canEdit={canEdit}
          />
          <CostDistribution
            form={form}
            costCenters={cost_centers}
            onInputChange={handleCostChange}
            costs={costs}
            onAddCost={handleAddCost}
            onDelete={handleDeleteCost}
            canEdit={canEdit}
          />
          <OrganizationalForm
            costs={costs}
            costCenters={cost_centers}
            form={form}
            onInputChange={handleInputChange}
            onSelectChange={handleSelectChange}
            personnelDivisions={personnel_divisions}
            staffAreas={staff_areas}
            companies={companies}
            positions={positions}
            organizationalUnits={organizational_units}
            subStaffAreas={sub_staff_areas}
            businessLines={business_lines}
            divisions={divisions}
            payrollAreas={payroll_areas}
            canEdit={canEdit}
          />
          {
            // De todas maneras agregué la condición de super admin, porque al parecer no lo podían ver
          }
          {(user.role === "superadministrator" ||
            !isUserRole(["administrator"])) && (
            <RemunerationsForm
              assignments={assignments}
              bonuses={bonuses}
              requestAssignments={requestAssignments}
              form={form}
              onInputChange={handleInputChange}
              onSelectChange={handleSelectChange}
              personalSubGroups={personal_sub_groups}
              requestBonuses={requestBonuses}
              onAddBonus={handleAddBonus}
              onDeleteBonus={handleDeleteBonus}
              onInputBonusChange={handleBonusChange}
              onBonusSelectChange={handleBonusSelectChange}
              onAddAssignment={handleAddAssignment}
              onDeleteAssignment={handleDeleteAssignment}
              onInputAssignmentChange={handleAssignmentChange}
              onAssignmentSelectChange={handleAssignmentSelectChange}
              canEdit={canEdit}
            />
          )}
          <BankForm
            form={form}
            onInputChange={handleInputChange}
            onSelectChange={handleSelectChange}
            banks={banks}
            bankControls={bank_controls}
            paymentMethods={payment_methods}
            canEdit={canEdit}
          />
        </section>
        <FilesForm
          files={requestFiles}
          filesMaintainers={files}
          onInputChange={handleFileChange}
          onEditFile={handleFileEdit}
          onDestroyFile={handleFileDestroy}
          canEdit={canEdit}
        />

        <div className="row mt-2">
          <div className="col-12">
            {((canEdit && (!id || form.status === "draft")) ||
              (errors.length && form.status === "waiting_for_validation")) && (
              <>
                <input
                  className="btn btn-warning mx-1 float-right"
                  type="submit"
                  formNoValidate
                  value="Guardar Borrador"
                  accessKey="s"
                  data-disable-with="Guardando ..."
                />
              </>
            )}
            {canEdit && (
              <button
                className="btn btn-danger float-right"
                type="submit"
                data-disable-with="Guardando ..."
                disabled={isSubmitted}
              >
                Guardar y Enviar
              </button>
            )}
            {form.id && (
              <button
                className="btn btn-info float-right mr-1"
                type="button"
                disabled={loadingPdf}
                onClick={handleDownload}
              >
                {loadingPdf && (
                  <span
                    className="spinner-border spinner-border-sm mr-1"
                    role="status"
                    aria-hidden="true"
                  />
                )}
                Descargar
              </button>
            )}
          </div>
        </div>
      </form>

      {form.id && (
        <div className="col-md-4 col-xs-12">
          <div className="sticky-top">
            {shouldRenderSideForm && (
              <>
                {(isAdmin() || isUserRole(["generalist"])) && (
                  <GeneralistAcceptForm
                    form={form}
                    user={user}
                    csrf={csrf}
                    formRef={formRef}
                  />
                )}
                {(isAdmin() || isUserRole(["master_data"])) && (
                  <MasterDataForm
                    personAcceptation={person_acceptation}
                    form={form}
                    isAdmin={isAdmin}
                    user={user}
                    csrf={csrf}
                    company={requestCompany}
                    setRequest={setForm}
                    handleInputChange={handleInputChange}
                    formRef={formRef}
                  />
                )}
                {(isAdmin() || isUserRole(["applicant"])) && (
                  <ApplicantForm form={form} csrf={csrf} formRef={formRef} />
                )}
              </>
            )}
            <Comments user={user} requestId={request.id} csrf={csrf} />
          </div>
        </div>
      )}
    </div>
  );
};

Form.propTypes = {
  request: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
};

export default Form;
