import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Select from "../../../components/Forms/Select";
import Input from "../../../components/Forms/Input";
import { getRequests } from "../../../services/requests";
import Filters from "./Filters";
import Table from "./Table";
import Graph from "./Graph";
import { REPORT_TYPE_OPTIONS, UNVALIDATED } from "./constants";

const Index = ({ companies, branchOffices, pagy }) => {
  const [requests, setRequests] = useState([]);
  const [pagination, setPagination] = useState(pagy);
  const [regionsData, setRegionsData] = useState([]);
  const [branchOfficesData, setBranchOfficesData] = useState([]);
  const [filters, setFilters] = useState({
    report_type: UNVALIDATED,
    company_id: "",
    branch_office_id: "",
    application_date_from: "",
    application_date_to: "",
    page: 1
  });

  const setParams = () => {
    let params = "?";
    Object.keys(filters).forEach(key => {
      if (filters[key]) params += `&${key}=${filters[key]}`;
    });
    return params;
  };

  const handlePageChange = (name, value) => {
    setFilters({ ...filters, [name]: value });
  };

  const getRequestsSuccess = ({ rows, pagination: newPagy, regions, branch_offices }) => {
    setPagination(newPagy);
    setRequests(rows);
    setRegionsData(regions);
    setBranchOfficesData(branch_offices);
  };

  useEffect(() => {
    getRequests(setParams()).then(
      response => getRequestsSuccess(response.data),
      err => console.log(err)
    );
  }, [filters]);

  return (
    <div className="row">
      <Filters
        filters={filters}
        setFilters={setFilters}
        companies={companies}
        branchOffices={branchOffices}
      />
      <div className="col-md-3 col-xs-12">
        <div className="btn-group-vertical d-flex justify-content-center mt-5">
          {REPORT_TYPE_OPTIONS.map(option => (
            <button
              key={option.value}
              type="button"
              onClick={() =>
                setFilters({ ...filters, report_type: option.value })
              }
              className={`btn btn-danger scp-secondary ${filters.report_type === option.value && "active"}`}
            >
              {option.label}
            </button>
          ))}
          <div className="card mt-5 border-3 border-primary">
            <div className="card-body">
              <h4 className="text-center text-black">{pagination?.count}</h4>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-6 col-xs-12 mt-5">
        <Table
          rows={requests}
          pagination={pagination}
          type={filters.report_type}
          handlePageChange={handlePageChange}
        />
      </div>
      <div className="col-md-3 col-xs-12">
        <Graph data={branchOfficesData} />
      </div>
    </div>
  );
};

Index.defaultProps = {
  companies: [],
  branchOffices: []
};

Index.propTypes = {
  companies: PropTypes.array,
  branchOffices: PropTypes.array,
  pagination: PropTypes.object.isRequired
};

export default Index;
