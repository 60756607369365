import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

const PersonAcceptationStatus = ({ personNumber, message }) => {
  return personNumber ? (
    <div className="alert alert-success">
      {message}
    </div>
  ) : (
    personNumber != 0 || personNumber == null ?
      (
        <div className="alert alert-warning">
          <span
            className="spinner-border spinner-border-sm mr-1"
            role="status"
            aria-hidden="true"
          />
          Persona en proceso de confirmación
        </div>
      ) : (
        <div className="alert alert-danger">{message}</div>
      )
        
  )
}

PersonAcceptationStatus.propTypes = {
  personNumber: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.oneOf([null])
  ]),
  message: PropTypes.string.isRequired,
}

export default PersonAcceptationStatus;