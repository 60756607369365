import React from "react"
import PropTypes from "prop-types";
import BonusRow from "./BonusRow";
import { objectTypeAnnotation } from "@babel/types";

const Bonus = ({
    bonuses,
    requestBonuses,
    canEdit,
    onAddBonus,
    onDeleteBonus,
    onInputBonusChange,
    onBonusSelectChange
}) => (
    <div className="card-body row">
        {canEdit && (
            <button
                className="btn btn-outline-info"
                type="button"
                onClick={onAddBonus}
            >
                <i className="fe fe-plus pr-1" />
                Bono
            </button>)}
        <div className="">
            <table className="table table-hover table-outline table-vcenter">
                <thead>
                    <tr>
                        <th>Bono</th>
                        <th>Tipo</th>
                        <th>Periodo</th>
                        <th>Monto</th>
                        {canEdit && <th>Acciones</th>}
                    </tr>
                </thead>
                <tbody>
                    {requestBonuses.map((bonus, idx) => {
                        if (bonus._destroy === true) return null;
                        return (
                            <BonusRow
                                bonuses={bonuses}
                                canEdit={canEdit}
                                bonus={bonus}
                                index={idx}
                                onChange={onInputBonusChange}
                                onSelectChange={onBonusSelectChange}
                                onDelete={onDeleteBonus}
                                key={idx + 12}
                            />
                        )
                    })}
                </tbody>
            </table>
        </div>
    </div>
)

Bonus.defaultProps = {
    bonuses: [],
}

Bonus.propTypes = {
    bonuses: PropTypes.array.isRequired,
}

export default Bonus;
