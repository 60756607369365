import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SelectInput from './Select';
import Select from './Select';
import Input from './Input';

const AssignmentRow = ({
  assignments,
  requestAssignment,
  onChange,
  onSelectChange,
  index,
  onDelete,
  canEdit,
}) => {

  const assignmentTypes = [
    { label: 'Fijo', value: 'static' },
    { label: 'Variable', value: 'variable' },
  ];

  const assignments_options = [
    { label: 'Diaria', value: 'daily' },
    { label: 'Mensual', value: 'monthly' },
  ];

  const [assignmentName, setAssignmentName] = useState(() =>
    assignments.find(a => a.label == requestAssignment.name)?.value
  );
  const setAssignment = (el, assign, index) => {
    requestAssignment.name = el.label;
    setAssignmentName(el.value);
    onChange({ target: { name: 'name', value: el.label } }, index);
  };
  return (
    <tr>
      <td>
        {canEdit ? (
          <Select
            key={`${index}-key`}
            label={'Asignación'}
            name={'name'}
            options={assignments}
            value={assignmentName}
            id={`${index}-req_assign`}
            onChange={(el) => setAssignment(el, requestAssignment, index)}
            required={true}
          />
        ) : (
            requestAssignment.name
        )}
      </td>
      <td>
        {canEdit ? (
          <SelectInput
            key={`${index}-type`}
            id={`${index}-assign`}
            name='assignment_type'
            value={requestAssignment.assignment_type}
            options={assignmentTypes}
            onChange={(v, e) => onSelectChange(v, e, index)}
            required
            label='Tipo'
          />
        ) : (
          assignmentTypes.find((b) => b.value === requestAssignment.assignment_type)?.label
        )}
      </td>
      <td>
        {canEdit ? (
          <SelectInput
            key={`${index}-type_per`}
            id={`${index}-assignment_per`}
            name="periodicity"
            value={requestAssignment.periodicity}
            options={assignments_options}
            onChange={(v, e) => onSelectChange(v, e, index)}
            required
            label='Periodo'
          />
        ) : (
          assignments_options.find(
            b => b.value === requestAssignment.periodicity
          )?.label
        )}
      </td>
      <td width={"15%"}>
        {canEdit ? (
          <Input
            style={ { width: '110px'} }
            key={`${index}-amount`}
            id={`${index}-assignemnt`}
            name="amount"
            type={"number"}
            value={requestAssignment.amount}
            onChange={e => onChange(e, index)}
            required
            //style={{ width: "150px" }}
            label="Monto"
          />
        ) : (
          requestAssignment.amount
        )}
      </td>
      {canEdit && (
        <td>
          <button
            key={`${index}-delete`}
            className='btn btn-danger'
            onClick={() => onDelete(index)}
            type='button'
          >
            <i className='fe fe-trash' />
          </button>
        </td>
      )}
    </tr>
  );
};

AssignmentRow.defaultProps = {};

AssignmentRow.propTypes = {
  requestAssignment: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
};

export default AssignmentRow;
