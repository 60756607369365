import React from "react";
import PropTypes from "prop-types";
import Table from "../../components/Table";
import Form from "./Form";

const Index = ({ companies, pagination, csrf, new_company }) => {
  const columns = [
    {
      label: "Nombre",
      key: "name",
      value: r => r.name
    },
    {
      label: "Código",
      key: "code",
      value: r => r.code
    },
    {
      label: "Email Contacto",
      key: "contact_email",
      value: r => r.contact_email
    },
    {
      label: "Con Contrato",
      key: "with_contract",
      value: r => (r.with_contract ? "Si" : "No")
    }
  ];

  return (
    <div className="card">
      <div className="card-header d-flex justify-content-between align-items-center">
        <h3 className="card-title">Empresas</h3>
        <button
          className="btn btn-info"
          type="button"
          data-bs-toggle="modal"
          data-bs-target="#newCompany"
        >
          Nueva Empresa
        </button>
      </div>
      <div className="card-body">
        <Table
          rows={companies}
          columns={columns}
          actions={["edit", "delete"]}
          entityClass="companies"
          pagination={pagination}
          editModal
        />
      </div>
      <Form csrf={csrf} company={new_company} modalId="newCompany" />
      {companies.map(company => (
        <Form
          key={company.id}
          csrf={csrf}
          company={company}
          modalId={`companies-${company.id}`}
        />
      ))}
    </div>
  );
};

Index.propTypes = {
  companies: PropTypes.array.isRequired,
  pagination: PropTypes.object.isRequired,
  csrf: PropTypes.string.isRequired,
  new_company: PropTypes.object.isRequired
};

export default Index;
