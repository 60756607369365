import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import Input from '../../../../components/Forms/Input';
import Select from '../../../../components/Forms/Select';
import InputInfo from '../../../../components/Layouts/InputInfo';
import TextArea from '../../../../components/Forms/TextArea';

const OrganizationalForm = ({
  form,
  onSelectChange,
  onInputChange,
  personnelDivisions,
  organizationalUnits,
  staffAreas,
  companies,
  subStaffAreas,
  positions,
  divisions,
  payrollAreas,
  businessLines,
  costs,
  costCenters,
  canEdit
}) => {
  const personnelDivisionsOptions = useMemo(() => {
    if (!form.company_id) return personnelDivisions;
    return personnelDivisions.filter((p) => p.company_id == form.company_id);
  }, [form.company_id]);

  const organizationalUnitsOptions = useMemo(() => {
    const agunsa = companies.find(
      (c) => c.label?.toString().includes('AGUNSA') && c.value == form.company_id
    );
    if (agunsa)
      return organizationalUnits.filter(
        (o) => o.company_id == form.company_id
      );
    if (!form.branch_office_id) return organizationalUnits;
    return organizationalUnits.filter(
      (o) => o.branch_office_id == form.branch_office_id
    );
  }, [form.branch_office_id, form.company_id]);

  const subStaffAreasOptions = useMemo(() => {
    if (!form.branch_office_id) return subStaffAreas;
    return subStaffAreas.filter(
      (s) =>
        s.personnel_division_id == form.personnel_division_id &&
        s.branch_office_id == form.branch_office_id
    );
  }, [form.branch_office_id, form.personnel_division_id]);

  const staffAreasOptions = useMemo(() => {
    if (!form.company_id) return staffAreas;
    return staffAreas.filter((s) => s.company_id == form.company_id);
  }, [form.company_id]);

  const payrollAreasOptions = useMemo(() => {
    if (!form.company_id) return payrollAreas;
    return payrollAreas.filter((p) => p.company_id == form.company_id);
  }, [form.company_id]);

  const [positionsLabel, positionsOptions] = useMemo(() => {
    if (!form.company_id) return ['', positions];
    let label = '';
    const options = [];
    positions.forEach((position) => {
      if (position.company_id == form.company_id) options.push(position);
      if (position.value == form.position_id) ({ label } = position);
    });
    return [label, options];
  }, [form.company_id]);

  const position_description = useMemo(() => {
    if (!form.position_id) return null;
    return positionsOptions.find(
      (position) => position.value === form.position_id
    )?.description;
  }, [form.position_id]);

  const businessLinesOptions = useMemo(() => {
    if (!costs[0].name) return businessLines;
    const selectedCosts = costCenters.filter(
      costCenter =>
        costCenter.label === costs.find(cost => cost.name === costCenter.label && !cost._destroy)?.name)
    const bLines = businessLines.filter(
      b => b.cost_center_id === selectedCosts.find(c => c.value === b.cost_center_id)?.value
    );
    const uniqueArray = [];
    const seenIds = new Set();
    bLines.forEach(obj => {
      if (!seenIds.has(obj.label)) {
        seenIds.add(obj.label);
        uniqueArray.push(obj);
      }
    });

    return uniqueArray;
  }, [costs]);

  const columns = useMemo(
    () => [
      {
        inputType: 'selector',
        type: 'text',
        label: 'Línea de Negocio',
        attribute_name: 'business_line',
        options: businessLinesOptions,
        required: true,
        value: (f) =>
          businessLinesOptions.find((bl) => bl.value == f.business_line)?.value,
      },
      {
        inputType: 'selector',
        label: 'División de Personal',
        attribute_name: 'personnel_division_id',
        options: personnelDivisionsOptions,
        required: true,
        value: (f) =>
          personnelDivisionsOptions.find(
            (pd) => pd.value == f.personnel_division_id
          )?.label,
      },
      {
        inputType: 'selector',
        label: 'Sub división de personal',
        attribute_name: 'sub_staff_area_id',
        options: subStaffAreasOptions,
        required: true,
        value: (f) =>
          subStaffAreasOptions.find((sa) => sa.value == f.sub_staff_area_id)
            ?.label,
      },
      {
        inputType: 'selector',
        label: 'Área de personal',
        attribute_name: 'staff_area_id',
        options: staffAreasOptions,
        required: true,
        value: (f) =>
          staffAreas.find((sa) => sa.value == f.staff_area_id)?.label,
      },
      {
        inputType: 'selector',
        label: 'Cargo',
        attribute_name: 'position_id',
        options: positionsOptions,
        required: true,
        value: () => positionsLabel,
      },
      {
        inputType: 'input',
        type: 'text',
        label: 'Jefatura',
        attribute_name: 'leadership',
        value: (f) => f.leadership,
      },
      {
        inputType: 'input',
        type: 'number',
        label: 'N° SAP',
        attribute_name: 'sap_number',
        value: (f) => f.sap_number,
      },
      {
        inputType: 'selector',
        label: 'División',
        attribute_name: 'division_id',
        options: divisions,
        required: true,
        value: (f) => divisions.find((sa) => sa.value == f.division_id)?.label,
      },
      {
        inputType: 'selector',
        label: 'Área de nómina',
        attribute_name: 'payroll_area_id',
        options: payrollAreasOptions,
        required: true,
        value: (f) =>
          payrollAreasOptions.find((pa) => pa.value == f.payroll_area_id)
            ?.label,
      },
      {
        inputType: 'selector',
        label: 'Unidad Organizativa',
        attribute_name: 'organizational_unit_id',
        required: true,
        options: organizationalUnitsOptions,
        value: (f) =>
          organizationalUnitsOptions.find(
            (o) => o.value == f.organizational_unit_id
          )?.label,
      },
    ],
    [
      form.company_id,
      form.position_id,
      form.business_line,
      form.personnel_division_id,
      form.staff_area_id,
      form.sub_staff_area_id,
      form.division_id,
      form.payroll_area_id,
      form.branch_office_id,
      costs,
    ]
  );

  return (
    <div className='card'>
      <div className='p-4 border-bottom'>
        <h4 className='card-title'>Datos organizacionales</h4>
      </div>
      <div className='card-body row g-3'>
        {columns.map((column) => {
          const { inputType, label, attribute_name, value } = column;
          if (!canEdit)
            if (attribute_name === 'position_id') {
              return (
                <div key={`${attribute_name}_div`}>
                  <InputInfo key={label} title={label} value={value(form) || form[attribute_name]} />
                  <TextArea
                    value={position_description || ''}
                    name={'position_description'}
                    placeholder={'Descripción del cargo'}
                    id={`entity-input-postision_description`}
                    disabled={'disabled'}
                  />
                </div>
              );
            } else {
              return (
                <InputInfo key={label} title={label} value={value(form) || form[attribute_name]} />
              );
            }
          if (attribute_name === 'position_id')
            return (
              <div key={`${attribute_name}_div`}>
                <Select
                  key={attribute_name}
                  label={label}
                  options={column.options}
                  value={form[attribute_name]}
                  name={attribute_name}
                  id={attribute_name}
                  onChange={onSelectChange}
                  required={column.required}
                />
                <TextArea
                  value={position_description || ''}
                  name={'position_description'}
                  placeholder={'Descripción del cargo'}
                  id={`entity-input-postision_description`}
                  disabled={'disabled'}
                />
              </div>
            );
          if (inputType === 'selector')
            return (
              <Select
                key={attribute_name}
                label={label}
                options={column.options}
                value={form[attribute_name]}
                name={attribute_name}
                id={attribute_name}
                onChange={onSelectChange}
                required={column.required}
              />
            );

          return (
            <Input
              key={attribute_name}
              value={form[attribute_name]}
              name={attribute_name}
              id={attribute_name}
              onChange={onInputChange}
              label={label}
              required={column.required}
              type={column.type}
            />
          );
        })}
      </div>
    </div>
  );
};

OrganizationalForm.defaultProps = {
  personnelDivisions: [],
  staffAreas: [],
  positions: [],
  subStaffAreas: [],
  divisions: [],
  payrollAreas: [],
  businessLines: [],
  costs: [],
};

OrganizationalForm.propTypes = {
  personnelDivisions: PropTypes.array,
  staffAreas: PropTypes.array,
  positions: PropTypes.array,
  subStaffAreas: PropTypes.array,
  divisions: PropTypes.array,
  payrollAreas: PropTypes.array,
  form: PropTypes.object.isRequired,
  onSelectChange: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
};

export default OrganizationalForm;
